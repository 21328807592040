#renderable-input .above {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.25rem; }
  #renderable-input .above label {
    flex-grow: 1; }

#renderable-input .inline {
  display: flex;
  align-items: center;
  gap: 1rem; }
  #renderable-input .inline label {
    flex-grow: 1; }
  #renderable-input .inline input {
    flex-shrink: 1;
    width: auto; }

#renderable-input input,
#renderable-input select {
  display: block;
  padding: 0.5rem;
  width: 100%; }

#renderable-input .description {
  color: var(--mm-grey); }

#renderable-input.error {
  color: var(--mm-error-color); }
  #renderable-input.error input {
    border: 1px solid var(--mm-error-color); }

#renderable-input.loading label {
  color: var(--mm-grey); }

#renderable-input .input-container {
  position: relative; }
  #renderable-input .input-container .decoration {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    cursor: pointer; }
