#timeline-editor-opening-modal {
  /* Avoids scrollbar when it's not needed. */
  padding: 0 !important;
  padding-bottom: 8px; }
  #timeline-editor-opening-modal .explanation {
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 2rem 1rem;
    background: var(--dark-white);
    flex-wrap: wrap; }
    #timeline-editor-opening-modal .explanation .icon {
      color: var(--mm-info-color);
      flex-basis: 0; }
    #timeline-editor-opening-modal .explanation p {
      flex-basis: 0;
      flex-grow: 1;
      margin: 0; }
    @media screen and (max-width: 767px) {
      #timeline-editor-opening-modal .explanation {
        justify-content: center; }
        #timeline-editor-opening-modal .explanation .button {
          flex-basis: 100%; } }
  #timeline-editor-opening-modal .opening-mode {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem; }
    @media screen and (max-width: 767px) {
      #timeline-editor-opening-modal .opening-mode {
        flex-direction: column; }
        #timeline-editor-opening-modal .opening-mode svg {
          width: 100%;
          height: 25vh; } }
    @media screen and (min-width: 768px) {
      #timeline-editor-opening-modal .opening-mode {
        gap: 1rem; }
        #timeline-editor-opening-modal .opening-mode > * {
          height: 330px; }
        #timeline-editor-opening-modal .opening-mode .svg {
          flex-basis: 50%; }
        #timeline-editor-opening-modal .opening-mode svg {
          width: 100%;
          height: 310px; } }
    #timeline-editor-opening-modal .opening-mode .svg {
      text-align: center; }
    #timeline-editor-opening-modal .opening-mode .opening-input {
      flex-basis: 50%;
      text-align: center; }
      #timeline-editor-opening-modal .opening-mode .opening-input .datepicker {
        min-height: 310px;
        overflow: visible; }
        #timeline-editor-opening-modal .opening-mode .opening-input .datepicker select {
          font-size: 16px;
          margin-top: 6px; }
  #timeline-editor-opening-modal .image-mode {
    padding: 1rem; }

#opening-image-row {
  display: flex;
  align-items: stretch;
  margin-bottom: 1rem;
  gap: 1rem;
  border-bottom: 1px solid var(--mm-light-grey);
  padding-bottom: 1rem; }
  #opening-image-row .image img {
    height: 128px;
    width: 128px;
    object-fit: cover;
    box-shadow: 0 0 2px RGBA(0, 0, 0, 0.5);
    border-radius: 3px; }
  #opening-image-row .image .current-date {
    text-align: center;
    padding: 5px 2px;
    overflow: hidden;
    width: 128px;
    white-space: nowrap;
    text-align: center;
    border-radius: 3px;
    font-size: 0.8rem; }
    #opening-image-row .image .current-date.ACCURATE {
      background: var(--mm-timeline-accurate);
      color: var(--mm-timeline-accurate-contrast); }
    #opening-image-row .image .current-date.INACCURATE {
      background: var(--mm-timeline-inaccurate);
      color: var(--mm-timeline-inaccurate-contrast); }
    #opening-image-row .image .current-date.MISSING {
      background: var(--mm-timeline-missing);
      color: var(--mm-timeline-missing-contrast); }
  #opening-image-row .info {
    flex-grow: 1;
    display: flex;
    flex-direction: column; }
  #opening-image-row .inferred {
    display: flex;
    flex-grow: 1; }
    #opening-image-row .inferred > * {
      flex-basis: 50%; }
  #opening-image-row .explicit {
    flex-grow: 1;
    margin-top: 1rem; }
  #opening-image-row .date .label {
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: bold; }
  #opening-image-row .date .unknown {
    color: var(--mm-grey); }
  #opening-image-row .date input {
    padding: 5px;
    border: 1px solid var(--mm-dark-grey);
    border-radius: 3px; }
