/*-------------------
      Site Colors 
--------------------*/
/*---  Main Colors  ---*/
/*---  Colors  ---*/
/*---  Light Colors  ---*/
/*---   Neutrals  ---*/
/*--- Colored Backgrounds ---*/
/*--- Colored Text ---*/
/*--- Colored Headers ---*/
/*--- Colored Border ---*/
/*-------------------
       Layout
--------------------*/
/*-------------------
      Mapmaker 
--------------------*/
/*-------------------
      Other 
--------------------*/
#message-page .icon-container {
  text-align: center;
  margin-bottom: 1em;
  color: #545454; }

#message-page .message {
  text-align: center;
  font-size: 1.5em;
  color: #545454; }
