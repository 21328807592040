#toast-messages {
  position: absolute;
  z-index: 1000;
  width: 100%;
  max-width: 800px;
  padding: 0.5rem;
  padding-bottom: 1rem;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%); }

#toast-message {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  padding: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  border-left: 10px solid;
  background: var(--mm-white);
  gap: 1rem;
  align-items: center;
  border-radius: 2px; }
  #toast-message.error {
    border-color: var(--mm-error-color); }
  #toast-message.warning {
    border-color: var(--mm-warning-color); }
  #toast-message.info {
    border-color: var(--mm-info-color); }
  #toast-message .icon {
    color: var(--mm-light-black);
    text-align: center;
    flex-shrink: 0;
    font-size: 40pt; }
  #toast-message .main {
    flex-grow: 1; }
    #toast-message .main .title {
      margin-bottom: 0.5rem; }
      #toast-message .main .title:empty {
        display: none; }
    #toast-message .main .content {
      color: var(--mm-light-black); }
  #toast-message .close {
    cursor: pointer;
    align-self: flex-start;
    color: var(--mm-grey); }
    #toast-message .close:hover {
      color: var(--mm-black); }
  @media screen and (max-width: 767px) {
    #toast-message {
      gap: 0.5rem;
      padding: 0.5rem; }
      #toast-message .icon {
        font-size: 32pt; } }
  @media screen and (min-width: 768px) {
    #toast-message .icon {
      font-size: 50pt; } }
