#cart-summary {
  background: var(--dark-white);
  padding: 1.5rem; }
  #cart-summary .row {
    display: flex;
    margin-top: 0.5em; }
    #cart-summary .row .label {
      text-transform: uppercase;
      flex-grow: 1;
      font-size: 0.9em; }
    #cart-summary .row .value {
      font-size: 1.2em; }
  #cart-summary .row.discount {
    color: var(--discount-color); }
  #cart-summary .row.subtotal {
    border-top: 1px solid var(--light-black);
    padding-top: 0.7em;
    font-size: 1.5em; }
  #cart-summary .row.customer-note {
    display: block;
    margin-top: 2rem;
    text-align: center; }
    #cart-summary .row.customer-note textarea {
      width: 100%;
      padding: 1rem;
      border-color: var(--light-grey);
      border-radius: 4px; }
      #cart-summary .row.customer-note textarea::placeholder {
        color: var(--grey); }
    #cart-summary .row.customer-note .error-message {
      color: var(--error-color); }
      #cart-summary .row.customer-note .error-message:empty {
        display: none; }
  #cart-summary .fine-print {
    color: var(--light-black);
    margin-top: 1em;
    font-size: 0.9em; }
  #cart-summary .call-to-action {
    margin-top: 1em; }
    #cart-summary .call-to-action .button {
      background-color: var(--accent-color);
      color: var(--accent-text-color); }
