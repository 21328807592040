.container {
  border-radius: 3px;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 2rem;

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    h3 {
      margin: 0;
    }
    .icon {
      color: var(--warning-color);
    }
  }

  .options {
    display: flex;
    > div {
      flex-basis: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }
    img {
      width: 70%;
      margin: 0 auto;
      object-fit: contain;
      flex-grow: 1;
    }
  }
}
