#timeline-editor {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 1rem;
  background: var(--mm-dark-white);
  position: relative; }
  #timeline-editor .create-banner {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    height: 220px; }
    #timeline-editor .create-banner svg {
      flex-shrink: 0;
      width: 160px;
      transform: rotate(-6deg);
      border: 3px solid var(--mm-white);
      box-shadow: 0 0 10px RGBA(0, 0, 0, 0.5);
      z-index: 1; }
    #timeline-editor .create-banner .callout {
      background: var(--mm-white);
      border-radius: 10px;
      text-align: center;
      padding: 1rem;
      margin-left: -50px;
      padding-left: calc(50px + 1rem);
      box-shadow: 0 0 4px RGBA(0, 0, 0, 0.3); }
  #timeline-editor .buttons {
    padding-top: 1rem;
    text-align: center; }
    #timeline-editor .buttons .instruction-button {
      border: none;
      color: var(--mm-light-black);
      background: var(--mm-dark-white); }
  #timeline-editor .opening-list {
    display: grid;
    justify-items: center; }
    @media screen and (max-width: 767px) {
      #timeline-editor .opening-list {
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
        gap: 1rem; } }
    @media screen and (min-width: 768px) {
      #timeline-editor .opening-list {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 1rem; } }
    #timeline-editor .opening-list .year-divider {
      grid-column: 1/-1;
      text-align: left;
      justify-self: start; }
    #timeline-editor .opening-list h2 {
      margin-top: 1rem;
      font-weight: normal; }
    #timeline-editor .opening-list .year-cell-header {
      text-align: left;
      margin-bottom: 0.5rem; }
  #timeline-editor .footer {
    padding: 1rem;
    padding-top: 2rem;
    text-align: center; }

#timeline-editor-instructions {
  position: relative;
  padding: 1rem;
  background: var(--mm-white);
  box-shadow: 0 0 3px RGBA(0, 0, 0, 0.5);
  max-width: 800px;
  margin: 1rem auto; }
  #timeline-editor-instructions .close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 3rem;
    color: var(--mm-grey);
    cursor: pointer; }
    #timeline-editor-instructions .close:hover {
      color: var(--mm-light-black); }
  #timeline-editor-instructions .overview {
    margin: 0 auto; }
    #timeline-editor-instructions .overview h3 {
      text-align: center;
      margin-top: 0; }
  #timeline-editor-instructions .demos {
    display: flex;
    justify-content: center;
    gap: 1rem; }
    @media screen and (max-width: 1000px) {
      #timeline-editor-instructions .demos {
        flex-direction: column;
        align-items: center; } }
    @media screen and (min-width: 1001px) {
      #timeline-editor-instructions .demos {
        flex-direction: row; } }
    #timeline-editor-instructions .demos .demo {
      display: flex;
      align-items: center; }
      #timeline-editor-instructions .demos .demo .sample {
        flex-shrink: 0;
        border-radius: 5px;
        box-shadow: 0 0 3px RGBA(0, 0, 0, 0.5);
        overflow: hidden;
        margin: 1rem;
        width: 100px; }
        #timeline-editor-instructions .demos .demo .sample .square {
          height: 100px;
          background: white; }
        #timeline-editor-instructions .demos .demo .sample .date {
          height: 25px;
          border-radius: 0 0 3px 3px; }
      #timeline-editor-instructions .demos .demo .description {
        max-width: 320px; }
        #timeline-editor-instructions .demos .demo .description .short {
          text-transform: uppercase;
          margin: 0;
          margin-bottom: 0.5rem; }
        #timeline-editor-instructions .demos .demo .description .details {
          color: var(--mm-dark-grey); }
      #timeline-editor-instructions .demos .demo.accurate .date {
        background: var(--mm-timeline-accurate); }
      #timeline-editor-instructions .demos .demo.accurate .short {
        color: var(--mm-timeline-accurate); }
      #timeline-editor-instructions .demos .demo.inaccurate .date {
        background: var(--mm-timeline-inaccurate); }
      #timeline-editor-instructions .demos .demo.inaccurate .short {
        color: var(--mm-dark-grey); }
