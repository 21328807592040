#opening-color-picker {
  position: absolute;
  z-index: 10;
  padding: 0.5em;
  background: var(--mm-white);
  border-radius: 4px;
  box-shadow: 0 1px 3px RGBA(0, 0, 0, 0.3); }
  #opening-color-picker .react-colorful {
    width: 160px;
    height: 120px; }
  #opening-color-picker div.hex-input {
    display: flex;
    margin-top: 0.5rem;
    align-items: center;
    justify-content: center;
    gap: 0.5rem; }
    #opening-color-picker div.hex-input label {
      text-align: center;
      font-size: 1.3rem;
      flex-basis: 20px; }
    #opening-color-picker div.hex-input input {
      width: 100px;
      padding: 4px;
      flex-grow: 1;
      text-align: center; }
    #opening-color-picker div.hex-input .eye-dropper,
    #opening-color-picker div.hex-input .trash {
      color: var(--grey);
      cursor: pointer; }
      #opening-color-picker div.hex-input .eye-dropper:hover,
      #opening-color-picker div.hex-input .trash:hover {
        color: var(--black); }
    #opening-color-picker div.hex-input:not(.has-background) .trash {
      color: var(--light-grey);
      pointer-events: none;
      cursor: initial; }
