#labelled-value-table {
  width: 100%;
  border-collapse: collapse; }
  #labelled-value-table.style-simple tr.value-row > td.label {
    font-size: 0.9rem;
    font-weight: bold;
    text-transform: uppercase; }
  #labelled-value-table.style-simple tr.value-row > td.value {
    min-width: 50%;
    white-space: nowrap; }
  #labelled-value-table.style-complex tr.value-row:not(:first-of-type) {
    border-top: 1px solid var(--mm-grey); }
  #labelled-value-table.style-complex tr.value-row > td.label {
    font-size: 0.9rem;
    font-weight: bold;
    text-transform: uppercase;
    padding-top: 0.5rem;
    padding-right: 1rem; }
  #labelled-value-table.style-complex tr.value-row > td.value {
    min-width: 50%;
    white-space: nowrap;
    padding-top: 0.5rem; }
  #labelled-value-table tr.note-row {
    font-size: 0.8rem;
    color: var(--mm-dark-grey); }
    #labelled-value-table tr.note-row > td {
      padding-top: 0.5rem;
      padding-right: 1rem;
      padding-bottom: 1rem; }
