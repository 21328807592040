#mapmaker-modal {
  /**
   * Default form styling inside modals.
   */
  /**
  * Everything below here is trying to get modals to scroll when larger than the screen.
  */
  display: flex !important;
  flex-direction: column;
  position: unset;
  max-height: calc(100% - 28px); }
  #mapmaker-modal form label {
    display: block;
    margin-bottom: 4px;
    font-weight: bold; }
  #mapmaker-modal form input {
    padding: 0.5em 0.75em;
    width: 100%; }
  #mapmaker-modal > .header {
    flex-shrink: 0; }
  #mapmaker-modal > .content {
    overflow-y: auto;
    flex-grow: 1; }
  #mapmaker-modal > .actions {
    flex-shrink: 0;
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
    padding-bottom: 14px !important; }

/* Mobile windows take up 100% of space */
@media (max-width: 767px) {
  .ui.modals.dimmer {
    padding: 0; }
  #mapmaker-modal {
    margin: 0;
    width: 100%;
    height: 100%;
    max-height: 100%; }
    #mapmaker-modal > .header {
      padding: 1rem !important; } }
