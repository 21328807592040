#selectable-setting {
  border: 1px solid;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s; }
  #selectable-setting:not(.selected):not(.disabled) {
    color: var(--mm-dark-grey);
    border-color: var(--mm-light-grey); }
    #selectable-setting:not(.selected):not(.disabled):hover {
      background-color: var(--mm-dark-white);
      border-color: var(--mm-light-black); }
      #selectable-setting:not(.selected):not(.disabled):hover > .icon {
        color: var(--mm-light-black); }
  #selectable-setting.selected {
    border-color: var(--mm-accent-color);
    background-color: var(--mm-accent-color-light); }
    #selectable-setting.selected > .icon {
      color: var(--mm-accent-color); }
  #selectable-setting.disabled {
    cursor: default;
    color: var(--mm-grey);
    background-color: var(--mm-dark-white);
    border-color: var(--mm-light-grey); }
