#walgreens-checkout-context-panel {
  position: relative;
  width: 100%; }
  #walgreens-checkout-context-panel .checklist-item {
    padding: 0.25em 0; }
    #walgreens-checkout-context-panel .checklist-item .icon.pencil {
      visibility: hidden; }
    #walgreens-checkout-context-panel .checklist-item.clickable:hover {
      cursor: pointer; }
      #walgreens-checkout-context-panel .checklist-item.clickable:hover .icon.pencil {
        visibility: visible; }
    #walgreens-checkout-context-panel .checklist-item .icon,
    #walgreens-checkout-context-panel .checklist-item .task {
      color: var(--mm-light-grey); }
    #walgreens-checkout-context-panel .checklist-item.completed .task {
      color: var(--mm-accent-color); }
    #walgreens-checkout-context-panel .checklist-item.active .task,
    #walgreens-checkout-context-panel .checklist-item.active .icon {
      color: var(--mm-grey);
      font-weight: normal; }
