#collaborator-sharing-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden; }
  #collaborator-sharing-view .header {
    flex-grow: 0;
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    z-index: 10; }
    #collaborator-sharing-view .header .title {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      align-items: center; }
      #collaborator-sharing-view .header .title h3 {
        display: inline-block;
        margin: 0; }
      #collaborator-sharing-view .header .title .label {
        max-width: 150px; }
      @media screen and (max-width: 767px) {
        #collaborator-sharing-view .header .title {
          gap: 0.25rem; }
          #collaborator-sharing-view .header .title .label {
            font-size: 0.8rem; } }
  #collaborator-sharing-view .no-suggestions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: var(--mm-grey);
    height: 100%; }
