@import url(~react-datepicker/dist/react-datepicker.css);
#timeline-date-input {
  position: relative;
  text-transform: uppercase;
  font-size: 1.3rem;
  cursor: pointer; }
  #timeline-date-input input {
    cursor: pointer;
    width: 100%;
    border: none;
    text-align: center;
    max-width: 100%;
    background: transparent;
    border: none;
    padding: 5px; }
  #timeline-date-input.ACCURATE {
    background: var(--mm-timeline-accurate); }
    #timeline-date-input.ACCURATE input {
      color: var(--mm-timeline-accurate-contrast); }
  #timeline-date-input.INACCURATE {
    background: var(--mm-timeline-inaccurate); }
    #timeline-date-input.INACCURATE input {
      color: var(--mm-timeline-inaccurate-contrast); }
  #timeline-date-input.MISSING {
    background: var(--mm-error-color); }
    #timeline-date-input.MISSING input {
      color: var(--mm-white); }
