#sandbox-display {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  background: var(--light-black);
  color: var(--mm-white); }
  #sandbox-display > * {
    max-height: 100%;
    max-width: 100%; }
  #sandbox-display .download-box {
    position: absolute;
    display: block;
    top: 1rem;
    right: 1rem; }
    #sandbox-display .download-box .download-button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.3rem;
      background: RGBA(255, 255, 255, 0.3);
      border-radius: 100px;
      width: 40px;
      height: 40px;
      cursor: pointer; }
      #sandbox-display .download-box .download-button:hover {
        background: RGBA(255, 255, 255, 0.4); }
      #sandbox-display .download-box .download-button i {
        margin: 0; }
    #sandbox-display .download-box.open .download-button {
      display: none; }
    #sandbox-display .download-box .menu {
      background: var(--mm-white);
      border-radius: 2px;
      min-width: 120px; }
    #sandbox-display .download-box ul {
      margin: 0;
      padding: 0;
      color: var(--mm-dark-grey);
      list-style: none; }
      #sandbox-display .download-box ul li {
        user-select: none;
        cursor: pointer;
        padding: 1rem; }
        #sandbox-display .download-box ul li:not(:last-of-type) {
          border-bottom: 1px solid var(--mm-grey); }
        #sandbox-display .download-box ul li:hover {
          background-color: var(--mm-light-grey); }
    #sandbox-display .download-box .spinner {
      color: var(--mm-dark-grey);
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #sandbox-display .download-box .spinner > span {
        display: block;
        padding: 1rem;
        text-align: center; }
    #sandbox-display .download-box.loading .menu > :not(.spinner) {
      visibility: hidden; }
    #sandbox-display .download-box:not(.loading) .spinner {
      display: none; }
  #sandbox-display:not(:hover) .download-box:not(.open) {
    display: none; }
