#sticker-order-controls {
  padding: 1em;
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  gap: 1rem; }
  @media screen and (max-width: 767px) {
    #sticker-order-controls {
      gap: 0.5rem;
      padding: 0.5rem;
      font-size: 1rem; } }
  #sticker-order-controls .menu-option label {
    margin-right: 0.5rem; }
  #sticker-order-controls .menu-option .menu-note {
    text-transform: none;
    font-weight: normal;
    color: var(--mm-dark-grey);
    font-size: 0.9rem;
    padding: 0 1rem;
    max-width: 260px;
    white-space: normal; }
