#mapmaker-file-card {
  border: 1px solid transparent;
  position: relative;
  cursor: pointer;
  line-height: 0;
  /* Hover enabled devices, AKA computers. */
  /* Non hover devices, AKA touchscreens. */ }
  #mapmaker-file-card > * {
    line-height: normal; }
  #mapmaker-file-card img {
    object-fit: contain;
    object-position: center;
    width: 100%; }
  #mapmaker-file-card .details {
    display: flex;
    gap: 0.5rem;
    background: var(--mm-white); }
    #mapmaker-file-card .details .info {
      display: flex;
      gap: 0.25rem;
      flex-direction: column;
      overflow: hidden;
      width: 100%; }
      #mapmaker-file-card .details .info h5 {
        font-weight: 700;
        margin: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
      #mapmaker-file-card .details .info .timestamp {
        color: var(--mm-grey);
        flex-grow: 1; }
    #mapmaker-file-card .details .buttons {
      text-align: right; }
      #mapmaker-file-card .details .buttons .delete-button {
        cursor: pointer;
        color: var(--mm-grey); }
      #mapmaker-file-card .details .buttons .delete-button:hover {
        color: var(--mm-accent-color); }
    #mapmaker-file-card .details .progress {
      display: flex; }
      #mapmaker-file-card .details .progress .pie-filling {
        text-overflow: ellipsis;
        text-align: center;
        line-height: 1.2;
        width: 100%;
        overflow: hidden; }
        #mapmaker-file-card .details .progress .pie-filling .number {
          font-weight: bold;
          font-size: 1.2rem; }
        #mapmaker-file-card .details .progress .pie-filling.completed .number {
          color: var(--mm-success-color); }
        #mapmaker-file-card .details .progress .pie-filling .label {
          font-size: 0.6rem;
          text-transform: uppercase; }
    #mapmaker-file-card .details .spacer {
      flex-grow: 1; }
  @media (hover: hover) {
    #mapmaker-file-card {
      overflow: hidden;
      border: 1px solid var(--mm-light-grey);
      height: 100%;
      display: flex;
      flex-direction: column;
      border-radius: 2px;
      transition: border-color 0.2s; }
      #mapmaker-file-card img {
        width: 100%;
        object-fit: contain;
        flex-grow: 1;
        aspect-ratio: 1.5;
        transition: transform 0.2s ease-in-out;
        overflow: hidden; }
      #mapmaker-file-card .details {
        z-index: 1;
        padding: 0.5rem;
        min-height: 92px;
        transition: box-shadow 0.4s; }
      #mapmaker-file-card:hover {
        border: 1px solid var(--mm-accent-color); }
        #mapmaker-file-card:hover .details {
          box-shadow: 0 0 5px RGBA(0, 0, 0, 0.6); }
        #mapmaker-file-card:hover img {
          transform: scale(1.3); } }
  @media (hover: none) {
    #mapmaker-file-card .details {
      padding: 0.5rem;
      margin-bottom: 1rem; } }

#file-viewer-policy-badge .label {
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.9rem;
  padding: 3px 6px;
  border-radius: 2px; }

#file-viewer-policy-badge.collaborator .label {
  margin-left: -3px;
  color: var(--mm-suggestion-color); }

#file-viewer-policy-badge.viewer .label {
  color: var(--mm-viewer-text-color); }

#file-viewer-policy-badge .description {
  font-size: 0.9rem;
  color: var(--dark-grey);
  margin-top: 4px; }
