.sticker-line-item .stickers {
  margin-bottom: 1rem; }

.sticker-line-item .sticker-thumbnail {
  position: relative; }
  .sticker-line-item .sticker-thumbnail .qty {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    font-weight: bold;
    text-align: center;
    background: var(mm-white);
    bottom: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
    color: var(--mm-light-black);
    padding: 0.25rem;
    border-radius: 10em;
    min-width: 2.4rem;
    height: 2.4rem;
    box-shadow: 0 0 5px RGBA(0, 0, 0, 1); }
