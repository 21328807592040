.mapmaker-finder-modal {
  height: 100%; }
  .mapmaker-finder-modal .help {
    text-align: center;
    margin-bottom: 1rem;
    color: var(--mm-dark-grey); }
  .mapmaker-finder-modal .mapmaker-finder-content {
    display: flex;
    flex-direction: column; }
    .mapmaker-finder-modal .mapmaker-finder-content input {
      font-size: 16px; }
  .mapmaker-finder-modal .no-matches {
    padding: 20vh 0;
    text-align: center;
    font-size: 1.3rem;
    color: var(--mm-light-black); }
  .mapmaker-finder-modal ul.design-list {
    flex-grow: 1;
    list-style: none;
    padding: 0; }
    .mapmaker-finder-modal ul.design-list li {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 0.5rem 1rem;
      cursor: pointer;
      /* Hover enabled devices, AKA computers. */
      /* Hover enabled devices, AKA computers. */ }
      .mapmaker-finder-modal ul.design-list li:not(:last-of-type) {
        border-bottom: 1px solid var(--mm-light-grey); }
      .mapmaker-finder-modal ul.design-list li .thumbnail {
        line-height: 0; }
        .mapmaker-finder-modal ul.design-list li .thumbnail img {
          width: 125px;
          max-width: 20vw;
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center; }
      .mapmaker-finder-modal ul.design-list li .info {
        flex-grow: 1; }
        .mapmaker-finder-modal ul.design-list li .info h3 {
          margin: 0; }
        .mapmaker-finder-modal ul.design-list li .info table {
          margin-top: 0.5rem;
          font-size: 0.9rem; }
          .mapmaker-finder-modal ul.design-list li .info table td.value {
            text-transform: capitalize; }
      .mapmaker-finder-modal ul.design-list li .add-icon {
        color: var(--mm-light-grey); }
      @media (hover: hover) {
        .mapmaker-finder-modal ul.design-list li:hover {
          color: var(--mm-accent-color); }
          .mapmaker-finder-modal ul.design-list li:hover .add-icon {
            color: var(--mm-accent-color); } }
      @media (hover: none) {
        .mapmaker-finder-modal ul.design-list li {
          /* Remove blue highlight on mobile tap */
          -webkit-tap-highlight-color: transparent; }
          .mapmaker-finder-modal ul.design-list li:active {
            color: var(--mm-accent-color); }
            .mapmaker-finder-modal ul.design-list li:active .add-icon {
              color: var(--mm-accent-color); } }
