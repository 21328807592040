#mapmaker-blog-post .hero {
  width: 100%;
  object-fit: contain;
  margin: 1rem 0; }

#mapmaker-blog-post .info {
  margin-bottom: 2rem; }
  #mapmaker-blog-post .info h1 {
    margin-bottom: 0.5rem;
    font-size: 5rem; }
  #mapmaker-blog-post .info h5 {
    margin: 0;
    color: var(--mm-dark-grey); }

#mapmaker-blog-post .recent-posts {
  border-top: 1px solid var(--mm-grey);
  margin-top: 8rem;
  padding-top: 3rem; }
  #mapmaker-blog-post .recent-posts .top-info {
    display: flex;
    align-items: center;
    margin-bottom: 1rem; }
    #mapmaker-blog-post .recent-posts .top-info h3 {
      margin: 0;
      flex-grow: 1; }
