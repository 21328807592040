#mapmaker-layout {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden; }
  #mapmaker-layout .ui.button:hover {
    filter: brightness(90%); }
  #mapmaker-layout .ui.button:active {
    filter: brightness(80%); }
  #mapmaker-layout #top-bar {
    width: 100%;
    z-index: 5; }
  #mapmaker-layout #main-and-nav {
    display: flex;
    flex-grow: 1;
    height: calc(100% - 56px); }
    @media screen and (min-width: 768px) {
      #mapmaker-layout #main-and-nav {
        flex-direction: row; } }
    @media screen and (max-width: 767px) {
      #mapmaker-layout #main-and-nav {
        flex-direction: column; } }
  #mapmaker-layout #main {
    width: 100%;
    display: flex;
    flex: 1;
    overflow: hidden;
    -webkit-overflow-scrolling: touch; }
    @media screen and (min-width: 768px) {
      #mapmaker-layout #main.two-column-layout {
        flex-direction: row; } }
    @media screen and (max-width: 767px) {
      #mapmaker-layout #main.two-column-layout {
        flex-direction: column; } }
  @media screen and (min-width: 768px) {
    #mapmaker-layout #top-bar {
      border-top: 1px solid var(--mm-light-grey);
      box-shadow: 0 2px 2px RGBA(0, 0, 0, 0.3); }
    #mapmaker-layout #nav {
      order: 1; }
    #mapmaker-layout #main {
      order: 2; } }
  @media screen and (max-width: 767px) {
    #mapmaker-layout {
      overflow: hidden; }
      #mapmaker-layout #top-bar {
        border-top: 1px solid var(--mm-light-grey);
        box-shadow: 0 2px 4px var(--mm-light-grey); }
      #mapmaker-layout #nav {
        order: 2; }
      #mapmaker-layout #main {
        order: 1; } }

#mapmaker-layout-main-context-panel {
  overflow-y: hidden; }
  @media screen and (max-width: 767px) {
    #mapmaker-layout-main-context-panel {
      order: 2;
      flex-shrink: 0;
      flex-grow: 1;
      z-index: 2;
      box-shadow: 0 -2px 2px RGBA(0, 0, 0, 0.3);
      transition: max-height 0.3s ease-in-out;
      max-height: 66px; }
      #mapmaker-layout-main-context-panel.expanded {
        max-height: 50%; } }
  @media screen and (min-width: 768px) {
    #mapmaker-layout-main-context-panel {
      flex-basis: 30%;
      min-width: 300px;
      max-width: 400px;
      z-index: 2;
      box-shadow: 2px 0 2px RGBA(0, 0, 0, 0.3);
      height: 100%;
      order: 1; } }

#mapmaker-layout-main-content {
  height: 100%;
  width: 100%;
  position: relative; }
  @media screen and (max-width: 767px) {
    #mapmaker-layout-main-content {
      order: 1; } }
  @media screen and (min-width: 768px) {
    #mapmaker-layout-main-content {
      flex-grow: 1;
      order: 2;
      height: 100%;
      flex-basis: 70%; } }
