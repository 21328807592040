#order-line-item {
  padding-bottom: 1rem;
  margin-bottom: 1rem; }
  #order-line-item:not(:last-of-type) {
    border-bottom: 1px solid var(--mm-grey); }
  #order-line-item .header {
    display: flex;
    margin-bottom: 1rem; }
    #order-line-item .header .name {
      flex-grow: 1;
      font-size: 1rem;
      font-weight: bold; }
      #order-line-item .header .name > a {
        color: var(--mm-black); }
    #order-line-item .header .price {
      font-size: 0.9rem;
      flex-shrink: 0;
      font-weight: bold; }
  #order-line-item .content {
    display: flex;
    flex-grow: 1;
    gap: 1em; }
    @media screen and (max-width: 767px) {
      #order-line-item .content img,
      #order-line-item .content .image-node {
        width: 128px;
        height: 128px; } }
    @media screen and (min-width: 768px) {
      #order-line-item .content img,
      #order-line-item .content .image-node {
        width: 172px;
        height: 172px; } }
    #order-line-item .content .image-node {
      display: flex;
      align-items: center;
      justify-content: center; }
    #order-line-item .content img {
      object-fit: contain; }
    #order-line-item .content .attributes {
      list-style: none;
      padding-left: 0; }
      #order-line-item .content .attributes li {
        font-size: 0.9rem; }
        #order-line-item .content .attributes li .key {
          min-width: 100px;
          text-transform: uppercase;
          font-weight: bold;
          margin-right: 1rem; }
