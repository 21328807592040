#project-top-bar-button {
  position: relative;
  height: 100%;
  display: flex;
  place-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 6px;
  margin-right: -0.5rem; }
  #project-top-bar-button .topBarButton {
    font-weight: bold; }
    #project-top-bar-button .topBarButton.isMenuButton {
      padding-right: 3em; }
  #project-top-bar-button .dropdown-button {
    pointer-events: all;
    padding-right: 1em;
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    padding-top: 10px; }
    #project-top-bar-button .dropdown-button:hover {
      background: RGBA(0, 0, 0, 0.1); }
  #project-top-bar-button .dropdown-menu .print-service-item {
    width: 360px;
    max-width: 90vw;
    overflow: hidden;
    display: flex;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent; }
    #project-top-bar-button .dropdown-menu .print-service-item:hover {
      border-left-color: var(--mm-accent-color); }
    #project-top-bar-button .dropdown-menu .print-service-item .icon {
      flex-grow: 0;
      width: 64px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center; }
      #project-top-bar-button .dropdown-menu .print-service-item .icon img {
        width: 64px;
        height: 64px;
        object-fit: contain; }
      #project-top-bar-button .dropdown-menu .print-service-item .icon .icon {
        font-size: 3em;
        color: var(--mm-grey); }
    #project-top-bar-button .dropdown-menu .print-service-item .text {
      flex-grow: 1;
      white-space: normal; }
      #project-top-bar-button .dropdown-menu .print-service-item .text .name {
        font-weight: 700;
        text-transform: uppercase; }
      #project-top-bar-button .dropdown-menu .print-service-item .text .description {
        margin-top: 0.5em;
        color: var(--mm-dark-grey); }

.top-bar-viewing-mode {
  font-weight: bold;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  text-align: center;
  flex-shrink: 0;
  white-space: nowrap; }
  .top-bar-viewing-mode > svg {
    margin-top: -2px; }

.top-bar-access-identifier {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem; }
  .top-bar-access-identifier > div {
    flex-shrink: 0;
    line-height: 1; }
  .top-bar-access-identifier > svg {
    margin-bottom: -3px; }
  .top-bar-access-identifier.collaborator {
    color: var(--mm-suggestion-color); }
