#mapmaker-toolbar-divider {
  background: var(--mm-grey); }
  @media screen and (min-width: 768px) {
    #mapmaker-toolbar-divider {
      width: 80%;
      height: 1px;
      min-height: 1px;
      margin-top: 6px;
      margin-bottom: 8px;
      overflow: hidden; } }
  @media screen and (max-width: 767px) {
    #mapmaker-toolbar-divider {
      width: 1px;
      height: 80%;
      margin-left: 2px; } }
