.mapmaker-finder-group-selection .header {
  display: flex;
  align-items: center; }
  .mapmaker-finder-group-selection .header i {
    cursor: pointer;
    color: var(--mm-grey); }
  .mapmaker-finder-group-selection .header h2 {
    margin: 0; }

.mapmaker-finder-group-selection .thumbnail {
  text-align: center; }
  .mapmaker-finder-group-selection .thumbnail img {
    height: 25vh; }

.mapmaker-finder-group-selection .instructions {
  margin: 1rem 2rem; }
  .mapmaker-finder-group-selection .instructions p {
    color: var(--mm-dark-grey); }

.mapmaker-finder-group-selection .list {
  list-style: none;
  padding: 0 2rem; }
  .mapmaker-finder-group-selection .list li {
    display: flex;
    width: 100%;
    font-size: 2rem;
    padding: 0.5rem;
    color: var(--mm-light-black);
    border-radius: 3px;
    cursor: pointer; }
    .mapmaker-finder-group-selection .list li .size {
      flex-grow: 1; }
    .mapmaker-finder-group-selection .list li i {
      color: var(--mm-light-grey); }
    .mapmaker-finder-group-selection .list li:hover {
      background: var(--mm-dark-white); }
      .mapmaker-finder-group-selection .list li:hover i {
        color: var(--mm-accent-color); }
