#context-panel-compact-button {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  user-select: none;
  padding: 1rem; }
  #context-panel-compact-button:active {
    background: var(--mm-accent-color);
    color: var(--mm-white); }
  #context-panel-compact-button.primary {
    display: none; }
  #context-panel-compact-button .badge {
    right: 0px;
    left: initial; }
  #context-panel-compact-button > .icon {
    margin: 0; }
  #context-panel-compact-button > .name {
    margin-top: 7pt;
    font-family: "Avenir Next" !important;
    font-size: 7pt;
    font-weight: bold;
    line-height: 0;
    min-width: 40px;
    text-align: center; }
