#collaborator-list {
  list-style: none;
  padding: 0;
  width: 100%;
  overflow-y: auto;
  flex-grow: 1;
  margin: 0; }
  #collaborator-list .empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 2rem;
    color: var(--mm-grey);
    text-align: center; }

#collaborator-list-item {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0.75rem 0.5rem;
  cursor: pointer;
  transition: background 0.2s; }
  #collaborator-list-item:hover {
    background: var(--mm-dark-white); }
  #collaborator-list-item.selected {
    background: var(--mm-dark-white); }
  #collaborator-list-item .icon {
    flex-basis: 40px;
    text-align: center;
    margin: 0;
    color: var(--mm-grey);
    flex-shrink: 0; }
    #collaborator-list-item .icon .number {
      display: inline-block;
      padding: 4px 8px;
      font-size: 16pt;
      color: var(--mm-white);
      background-color: var(--mm-grey);
      border-radius: 40px; }
  #collaborator-list-item .name {
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
    line-height: 1.2; }
    #collaborator-list-item .name .email {
      font-size: 1rem;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    #collaborator-list-item .name .status {
      color: var(--mm-grey);
      font-size: 0.8rem; }
  #collaborator-list-item .edit {
    display: none;
    color: var(--mm-dark-grey); }
    #collaborator-list-item .edit:hover {
      color: var(--mm-black); }
    #collaborator-list-item .edit:active {
      margin-top: 1px;
      margin-bottom: -1px; }
  #collaborator-list-item.selected .edit,
  #collaborator-list-item:hover .edit {
    display: block; }
