#gift-options {
  margin-bottom: 0.5rem;
  display: flex;
  gap: 0.5rem; }
  #gift-options #gift-button {
    border-color: var(--grey); }
    #gift-options #gift-button.open {
      color: var(--accent-color); }
      #gift-options #gift-button.open .icon {
        color: var(--accent-color); }
    #gift-options #gift-button .icon {
      position: absolute;
      left: 1rem;
      top: 50%;
      transform: translateY(-50%); }

.gift-note-preview,
.sticker-tokens-preview {
  display: flex;
  align-items: stretch;
  margin-bottom: 0.5rem;
  background: var(--mm-white);
  border: 1px solid var(--mm-grey);
  border-radius: 3px;
  cursor: pointer; }
  .gift-note-preview .plus,
  .sticker-tokens-preview .plus {
    display: flex;
    flex-basis: 50px;
    aspect-ratio: 1;
    align-items: center;
    justify-content: center;
    font-size: 16pt;
    background: var(--mm-light-grey);
    color: var(--mm-grey);
    padding: 0.5rem; }
  .gift-note-preview .content,
  .sticker-tokens-preview .content {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 0.5rem; }

.gift-note-preview:hover {
  border-color: var(--mm-accent-color); }

.gift-note-preview .note {
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line; }

.gift-note-preview .bow {
  height: 25px; }
