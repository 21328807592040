#product-buy-box .price {
  display: block;
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
  margin-top: 1rem; }
  #product-buy-box .price .separator {
    margin: 4px; }
  #product-buy-box .price .each {
    font-size: 1rem; }

#product-buy-box .free-shipping {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--mm-discount-color);
  margin-top: 0;
  margin-bottom: 1rem; }

@media (max-width: 767px) {
  #product-buy-box > .title {
    padding-top: 2rem; } }

#product-buy-box .description {
  margin-bottom: 2rem;
  font-size: 1.1rem;
  line-height: 1.4285em; }

#product-buy-box .option-inputs {
  --option-gap: 0.5rem;
  --label-width: 150px;
  --label-gap: 1rem;
  margin-bottom: 0.5rem; }
  @media (max-width: 767px) {
    #product-buy-box .option-inputs {
      --label-width: 110px; } }

#product-buy-box .select {
  padding: 10px;
  width: 100%; }

#product-buy-box .input {
  width: 100%; }

#product-buy-box .button:not(.disabled) {
  background: var(--mm-accent-color);
  color: var(--mm-accent-text-color); }

#product-buy-box .addToCartError {
  margin-top: 0.5rem;
  color: var(--mm-error-color);
  text-align: center; }
