#mapmaker-success-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  #mapmaker-success-modal .main-icon {
    display: flex;
    flex-basis: 400px;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    #mapmaker-success-modal .main-icon .icon {
      animation: fadeIn 2s; }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
