#region-completion-sharing-modal {
  padding: 0 !important;
  overflow-y: hidden !important;
  display: flex;
  flex-direction: column; }
  #region-completion-sharing-modal .image-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    flex-shrink: 1;
    flex-grow: 1;
    padding: 1.5rem; }
    #region-completion-sharing-modal .image-container svg {
      border: 5px solid var(--mm-white);
      box-shadow: 0 0 1.5rem RGBA(0, 0, 0, 0.5); }
  #region-completion-sharing-modal .options .main {
    padding: 1.5rem; }
    #region-completion-sharing-modal .options .main label {
      margin-top: 1rem;
      text-transform: capitalize;
      display: block; }
    #region-completion-sharing-modal .options .main .state-select {
      /* Overrides to Semantic styles */
      /* End Overrides. */ }
      #region-completion-sharing-modal .options .main .state-select .menu {
        max-height: 50vh; }
      #region-completion-sharing-modal .options .main .state-select .divider.text {
        width: 100%; }
      #region-completion-sharing-modal .options .main .state-select .item.disabled {
        margin: 0;
        padding: 0 !important;
        opacity: 1; }
      #region-completion-sharing-modal .options .main .state-select .opening-option {
        display: flex;
        width: 100%;
        gap: 1rem;
        align-items: center; }
        #region-completion-sharing-modal .options .main .state-select .opening-option .number {
          font-size: 1.3rem;
          flex-basis: 30px;
          text-align: center; }
        #region-completion-sharing-modal .options .main .state-select .opening-option .name {
          flex-grow: 1; }
        #region-completion-sharing-modal .options .main .state-select .opening-option .date {
          font-size: 0.8rem;
          color: var(--mm-grey); }
      #region-completion-sharing-modal .options .main .state-select .year-divider {
        background: var(--mm-dark-grey);
        margin: 0;
        font-weight: bold;
        color: var(--mm-white);
        padding: 0.25rem 1rem;
        text-align: center; }
  #region-completion-sharing-modal .options .timeline-message {
    color: var(--mm-dark-grey);
    display: flex;
    align-items: center;
    gap: 1rem;
    background: var(--dark-white);
    padding: 1rem; }
    #region-completion-sharing-modal .options .timeline-message > .icon {
      color: var(--mm-info-color); }
    #region-completion-sharing-modal .options .timeline-message .link {
      color: var(--mm-black);
      text-decoration: underline;
      cursor: pointer; }
      #region-completion-sharing-modal .options .timeline-message .link:hover {
        color: var(--mm-light-black); }
