#contact-question-form {
  margin: 0 auto 2rem auto;
  max-width: 800px; }
  #contact-question-form label {
    margin-top: 1.5rem;
    font-size: 1.1em; }
  #contact-question-form .secondary {
    color: var(--dark-grey); }
  #contact-question-form .file-field .drop-target {
    margin-top: 0.5rem;
    padding: 2em 1em;
    text-align: center;
    border: 1px solid var(--light-grey);
    border-radius: 3px; }
    #contact-question-form .file-field .drop-target p {
      user-select: none; }
    #contact-question-form .file-field .drop-target.drag-active {
      border-color: var(--accent-color); }
  #contact-question-form .file-field .upload-item {
    margin-top: 0.5em; }
    #contact-question-form .file-field .upload-item .close:hover {
      color: var(--black); }
  #contact-question-form .buttons {
    text-align: right; }
