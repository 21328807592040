#change-password-box {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap; }
  #change-password-box .edit {
    color: var(--link-color, #4545e6);
    cursor: pointer;
    display: inline-block;
    margin-left: 0.5rem; }
  #change-password-box .success {
    color: var(--success-color, #008800);
    flex-basis: 100%; }
  #change-password-box form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%; }
    #change-password-box form button {
      width: auto; }
    #change-password-box form .error {
      margin-top: 0.5rem;
      color: var(--error-color, #880000); }
