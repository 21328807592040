#walgreens-checkout-step-container {
  position: relative;
  width: 100%;
  padding: 2em 1em;
  max-width: 800px;
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    #walgreens-checkout-step-container {
      padding-bottom: 2em; } }
  #walgreens-checkout-step-container .main-header {
    text-align: center; }
  #walgreens-checkout-step-container a {
    color: var(--mm-walgreens-red); }
  #walgreens-checkout-step-container .button.walgreens {
    border: 1px solid var(--mm-walgreens-red);
    color: var(--mm-walgreens-red) !important;
    box-shadow: none; }
    #walgreens-checkout-step-container .button.walgreens:hover {
      box-shadow: 0 0 0 1px rgba(224, 43, 43, 0.35) inset, 0 0 0 0 rgba(216, 39, 39, 0.15) inset; }
