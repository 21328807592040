#order-history #order-row {
  margin-top: 1rem; }
  #order-history #order-row:not(:nth-last-of-type(1)) {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid var(--mm-light-grey); }
  #order-history #order-row .top {
    display: flex;
    margin-bottom: 0.5rem; }
    #order-history #order-row .top .number {
      font-size: 1.5em;
      font-weight: bold;
      margin-right: 1em; }
      #order-history #order-row .top .number a {
        color: var(--mm-accent-color); }
      #order-history #order-row .top .number.cancelled a {
        color: var(--mm-error-color); }
      #order-history #order-row .top .number.cancelled .status {
        display: block;
        font-size: 1rem;
        text-transform: uppercase;
        color: var(--mm-grey); }
  #order-history #order-row .details {
    display: flex; }
    #order-history #order-row .details .summary h6 {
      font-size: 0.9rem;
      text-transform: uppercase;
      margin: 0;
      margin-top: 1rem; }
    @media screen and (min-width: 1100px) {
      #order-history #order-row .details {
        flex-direction: row; }
        #order-history #order-row .details .summary {
          flex-basis: 200px; } }
    @media screen and (max-width: 1099px) {
      #order-history #order-row .details {
        flex-direction: column; } }
  #order-history #order-row .items {
    flex-grow: 1;
    vertical-align: top; }
    #order-history #order-row .items img,
    #order-history #order-row .items .more-items {
      width: 200px;
      max-width: calc(25% - 1rem);
      aspect-ratio: 16/9;
      margin: 0.5rem;
      box-shadow: 0 0 3px RGBA(0, 0, 0, 0.3);
      cursor: pointer; }
      #order-history #order-row .items img:first-child,
      #order-history #order-row .items .more-items:first-child {
        margin-left: 0; }
    #order-history #order-row .items img {
      object-fit: cover; }
    #order-history #order-row .items .more-items {
      color: var(--mm-grey);
      display: inline-flex;
      flex-direction: column;
      vertical-align: top;
      align-items: center;
      justify-content: center;
      gap: 1rem; }
      #order-history #order-row .items .more-items:hover {
        color: var(--mm-light-black); }
