#order-details .cancelled {
  color: var(--mm-error-color); }

#order-details .order {
  display: flex;
  gap: 1rem; }
  @media screen and (max-width: 1099px) {
    #order-details .order {
      flex-direction: column; } }
  #order-details .order section {
    margin-top: 1rem; }
    #order-details .order section > h5 {
      display: flex;
      font-size: 1.2rem;
      border-bottom: 2px solid var(--mm-accent-color);
      padding-bottom: 0.5rem; }
  #order-details .order .items {
    flex-grow: 1; }

#order-details .summary {
  flex-shrink: 0;
  flex-basis: 300px; }
  #order-details .summary h6 {
    font-size: 0.9rem;
    text-transform: uppercase;
    margin: 0;
    margin-top: 1rem; }
  #order-details .summary .subtle {
    font-size: 0.9rem;
    color: var(--grey); }
  #order-details .summary ul.totals {
    list-style: none;
    padding: 0;
    margin-top: 0; }
    #order-details .summary ul.totals li {
      font-size: 0.9rem;
      font-weight: normal;
      display: flex; }
      #order-details .summary ul.totals li .key {
        text-transform: uppercase;
        flex-grow: 1; }
      #order-details .summary ul.totals li .value {
        display: inline-block;
        min-width: 80px;
        text-align: right; }
      #order-details .summary ul.totals li.total {
        font-weight: bold; }
        #order-details .summary ul.totals li.total .value {
          font-size: 1.5rem; }
      #order-details .summary ul.totals li.refund {
        margin-top: 1rem;
        color: var(--mm-warning-color); }
