:root {
  /* Mapmaker layout "Theme" */
  --mm-background-color: var(--background-color, --mm-white);
  --mm-text-color: var(--text-color, --mm-black);
  --mm-link-color: var(--link-color, --accent-color);
  --mm-accent-color: var(--accent-color, #f70fff);
  --mm-accent-color-light: var(--accent-color-light, #f70fff);
  --mm-accent-text-color: var(--mm-white);
  --mm-success-color: var(--success-color, #039634);
  --mm-success-text-color: var(--mm-white);
  --mm-info-color: var(--info-color, #424b54);
  --mm-info-text-color: var(--info-text-color, var(--mm-white));
  --mm-info-color-light: var(--info-color-light, #abcae9);
  --mm-warning-color: var(--warning-color, #f2711c);
  --mm-warning-text-color: var(--mm-white);
  --mm-error-color: var(--error-color, #db2828);
  --mm-error-text-color: var(--mm-white);
  --mm-discount-text-color: var(--discount-text-color, var(--mm-white));
  --mm-discount-color: var(--discount-color, #1a8f60);
  --mm-black: var(--black, #272725);
  --mm-light-black: var(--light-black, #545454);
  --mm-dark-grey: var(--dark-grey, #6e6e6e);
  --mm-grey: var(--grey, #999999);
  --mm-light-grey: var(--light-grey, #dcddde);
  --mm-dark-white: var(--dark-white, #f3f4f5);
  --mm-white: var(--white, #ffffff);
  --mm-walgreens-red: #e51937;
  /* Timeline colors */
  --mm-timeline-accurate: var(--mm-success-color, #3b9420);
  --mm-timeline-accurate-contrast: var(--mm-success-text-color);
  --mm-timeline-inaccurate: var(--mm-light-grey);
  --mm-timeline-inaccurate-contrast: var(--mm-light-black);
  --mm-timeline-missing: var(--mm-error-color);
  --mm-timeline-missing-contrast: var(--mm-error-text-color);
  /* Sharing colors */
  --mm-suggestion-color: #2679ff;
  --mm-suggestion-text-color: #ffffff;
  --mm-collaborator-color: var(--mm-info-color);
  --mm-collaborator-text-color: var(--mm-white);
  --mm-viewer-color: var(--mm-light-grey);
  --mm-viewer-text-color: var(--mm-light-black);
  --mm-owner-color: var(--mm-light-black);
  --mm-owner-text-color: var(--mm-white); }

span.link {
  cursor: pointer;
  color: var(--mm-link-color); }
  span.link.inverted {
    color: var(--mm-dark-white);
    text-decoration: underline; }
  span.link:hover {
    filter: brightness(0.8); }

/* Remove arrows ("spinners") from number inputs. */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */ }

input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */ }
