#blog-post-card {
  margin-bottom: 3rem;
  cursor: pointer; }
  #blog-post-card img {
    width: 100%; }
  #blog-post-card .info {
    padding: 0.5rem; }
    #blog-post-card .info h3 {
      margin: 0;
      margin-bottom: 0.5rem; }
    #blog-post-card .info .date {
      font-size: 0.9rem;
      text-transform: uppercase;
      color: var(--mm-grey); }
  #blog-post-card:hover .info {
    color: var(--mm-accent-color); }
  #blog-post-card:hover .date {
    color: var(--mm-accent-color); }
