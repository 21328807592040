/*-------------------
      Site Colors 
--------------------*/
/*---  Main Colors  ---*/
/*---  Colors  ---*/
/*---  Light Colors  ---*/
/*---   Neutrals  ---*/
/*--- Colored Backgrounds ---*/
/*--- Colored Text ---*/
/*--- Colored Headers ---*/
/*--- Colored Border ---*/
/*-------------------
       Layout
--------------------*/
/*-------------------
      Mapmaker 
--------------------*/
/*-------------------
      Other 
--------------------*/
#walgreens-get-customer-information {
  margin-top: 2em;
  padding-bottom: 3em; }
  #walgreens-get-customer-information p {
    font-size: 12pt; }
  #walgreens-get-customer-information input {
    font-weight: 300; }
  #walgreens-get-customer-information label {
    font-size: 10pt;
    text-transform: uppercase;
    font-weight: normal; }
