.discount-application-indicator {
  color: var(--discount-color); }
  .discount-application-indicator .label {
    background: var(--discount-color);
    color: var(--white);
    font-size: 0.7em;
    padding: 0 6px;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 2px;
    vertical-align: top;
    white-space: nowrap; }
    .discount-application-indicator .label .icon {
      font-size: 0.9em; }
