#context-panel-compact {
  width: 100%;
  /* Large displays */ }
  @media screen and (min-width: 768px) {
    #context-panel-compact {
      /* The menu bar is not shown on large displays. */
      display: none; } }

#context-panel-compact-menu {
  display: flex;
  justify-content: center;
  flex-direction: row; }
