#sanity-screenshot {
  line-height: 0.5;
  padding: 2rem;
  text-align: center; }
  #sanity-screenshot .image {
    max-width: 100%;
    box-shadow: 0 1px 3px RGBA(0, 0, 0, 0.5);
    border-radius: 5px; }
  #sanity-screenshot .caption {
    margin-top: 0.5rem;
    line-height: initial;
    text-align: center;
    font-weight: bold;
    font-size: 0.9rem;
    padding: 0 1rem; }
