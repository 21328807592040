.mapmaker-tilebar {
  background: var(--mm-light-black);
  display: flex; }
  @media screen and (min-width: 768px) {
    .mapmaker-tilebar {
      justify-self: stretch;
      flex-direction: column;
      overflow-y: auto;
      padding-top: 5px; }
      .mapmaker-tilebar > * {
        flex-shrink: 0;
        width: 70px;
        height: 70px;
        aspect-ratio: 1;
        margin: 5px;
        margin-bottom: 0px; }
      .mapmaker-tilebar::after {
        content: "";
        width: 0px;
        height: 0px;
        margin-top: 5px;
        border: 1px solid transparent; } }
  @media screen and (max-width: 767px) {
    .mapmaker-tilebar {
      flex-direction: row;
      overflow-x: auto;
      -ms-overflow-style: none;
      /* Internet Explorer 10+ */
      scrollbar-width: none;
      /* Firefox */ }
      .mapmaker-tilebar::-webkit-scrollbar {
        display: none;
        /* Safari and Chrome */ }
      .mapmaker-tilebar > * {
        width: 60px;
        height: 60px;
        aspect-ratio: 1;
        margin: 6px;
        margin-right: 0px; }
      .mapmaker-tilebar::after {
        content: "";
        width: 0px;
        height: 0px;
        margin-left: 5px;
        border: 1px solid transparent; } }
