.container {
  display: flex;
  gap: 0.5rem;

  .cartLabel {
    font-weight: bold;
    font-size: 0.8rem;
    color: var(--white) !important;
    background-color: var(--accent-color) !important;
    border-color: var(--accent-color) !important;
  }

  .badge {
    color: var(--accent-text-color);
    margin-top: -2px;
    margin-bottom: -2px;
    font-size: 10pt;
  }

  &.loading {
    opacity: 0.5;
  }
}
