#centered-block {
  width: 100%;
  height: 100%;
  position: relative;

  .content {
    display: inline-block;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}
