/*-------------------
      Site Colors 
--------------------*/
/*---  Main Colors  ---*/
/*---  Colors  ---*/
/*---  Light Colors  ---*/
/*---   Neutrals  ---*/
/*--- Colored Backgrounds ---*/
/*--- Colored Text ---*/
/*--- Colored Headers ---*/
/*--- Colored Border ---*/
/*-------------------
       Layout
--------------------*/
/*-------------------
      Mapmaker 
--------------------*/
/*-------------------
      Other 
--------------------*/
#walgreens-context-panel-header {
  width: 100%;
  text-align: center; }
  #walgreens-context-panel-header img {
    margin: 0 auto; }
  @media screen and (min-width: 768px) {
    #walgreens-context-panel-header {
      padding: 1em; } }
  @media screen and (max-width: 767px) {
    #walgreens-context-panel-header {
      height: 44px;
      padding: 10px 1em 10px 1em; } }
