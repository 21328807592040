.sticker-output-description-modal h3 {
  text-align: center; }

.sticker-output-description-modal .output-info {
  display: flex;
  align-items: center;
  gap: 1rem; }
  @media screen and (max-width: 767px) {
    .sticker-output-description-modal .output-info {
      flex-direction: column; } }
  .sticker-output-description-modal .output-info svg {
    flex-shrink: 0; }
  .sticker-output-description-modal .output-info div {
    flex-grow: 1; }

.sticker-output-description-modal .multiple-output-options-warning {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 2px;
  border: 1px solid var(--mm-info-color); }
  @media screen and (max-width: 767px) {
    .sticker-output-description-modal .multiple-output-options-warning {
      margin-top: 2rem; } }
  .sticker-output-description-modal .multiple-output-options-warning > .icon {
    color: var(--mm-info-color); }
