#timeline-editor-opening-card {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 0.25rem; }
  #timeline-editor-opening-card .card {
    cursor: pointer;
    background: var(--mm-white);
    box-shadow: 0 0 2px RGBA(0, 0, 0, 0.5); }
    #timeline-editor-opening-card .card:hover {
      border-color: var(--mm-accent-color);
      box-shadow: 0 0 2px RGBA(0, 0, 0, 0.7); }
    #timeline-editor-opening-card .card .name {
      margin: 0;
      margin-top: 0.5rem; }
    #timeline-editor-opening-card .card .date {
      border-top: 1px solid var(--mm-light-grey);
      padding: 5px;
      max-width: 100%;
      overflow: hidden; }
      #timeline-editor-opening-card .card .date.ACCURATE {
        background: var(--mm-timeline-accurate);
        color: var(--mm-timeline-accurate-contrast); }
      #timeline-editor-opening-card .card .date.INACCURATE {
        background: var(--mm-timeline-inaccurate);
        color: var(--mm-timeline-inaccurate-contrast); }
      #timeline-editor-opening-card .card .date.MISSING {
        background: var(--mm-error-color);
        color: var(--mm-white); }
