#sanity-aside {
  padding: 1rem;
  border-left: 3px solid;
  margin-bottom: 1rem; }
  #sanity-aside .heading {
    font-weight: bold;
    margin-bottom: 0.5rem; }
  #sanity-aside.tip {
    background: var(--mm-info-color-light);
    border-color: var(--mm-info-color);
    color: var(--mm-light-black); }
