@charset "UTF-8";
#opening-suggestion-list-item {
  display: flex;
  position: relative;
  justify-content: center; }
  #opening-suggestion-list-item.standalone-mode {
    border: none !important; }
  #opening-suggestion-list-item.list-mode {
    background: var(--mm-white);
    height: 100%; }
    #opening-suggestion-list-item.list-mode:not(.disabled) {
      cursor: pointer; }
  @media screen and (max-width: 767px) {
    #opening-suggestion-list-item {
      flex-direction: column;
      border-bottom: 1px solid var(--mm-grey);
      padding: 1rem; }
      #opening-suggestion-list-item:not(.disabled):active {
        background: var(--mm-dark-white); } }
  @media screen and (min-width: 768px) {
    #opening-suggestion-list-item {
      flex-direction: row;
      gap: 1rem;
      padding: 1rem; }
      #opening-suggestion-list-item.list-mode {
        box-shadow: 0 0 2px 1px var(--mm-light-grey);
        border-radius: 2px; }
        #opening-suggestion-list-item.list-mode:not(.disabled):hover {
          box-shadow: 0 0 2px 1px var(--mm-grey); }
        #opening-suggestion-list-item.list-mode.disabled {
          background: var(--mm-dark-white); } }
  #opening-suggestion-list-item .thumbnail {
    flex-grow: 0;
    align-self: center; }
    #opening-suggestion-list-item .thumbnail .stroke {
      stroke-width: 2;
      stroke: var(--mm-light-grey); }
  #opening-suggestion-list-item .delete {
    position: absolute; }
    #opening-suggestion-list-item .delete button {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      color: var(--mm-dark-grey); }
      #opening-suggestion-list-item .delete button:hover {
        color: var(--mm-black); }
  #opening-suggestion-list-item.list-mode .delete {
    right: 1rem;
    bottom: 1rem; }
  #opening-suggestion-list-item.standalone-mode .delete {
    right: 1rem;
    top: 1rem; }
  #opening-suggestion-list-item .info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem; }
    #opening-suggestion-list-item .info .title .pre-title {
      font-size: 0.8rem;
      text-transform: uppercase; }
    #opening-suggestion-list-item .info .title .title {
      margin: 0;
      font-weight: bold;
      margin-top: -3px; }
    #opening-suggestion-list-item .info .details {
      flex-grow: 1;
      font-size: 0.9rem;
      color: var(--mm-dark-grey); }
      #opening-suggestion-list-item .info .details .icon {
        margin-bottom: -2px;
        color: var(--mm-grey); }
      #opening-suggestion-list-item .info .details blockquote {
        quotes: "“" "”" "‘" "’";
        padding-left: 4px;
        padding-right: 5px;
        margin: 0; }
      #opening-suggestion-list-item .info .details blockquote:before,
      #opening-suggestion-list-item .info .details blockquote:after {
        display: inline;
        height: 0;
        line-height: 0;
        position: relative;
        top: 1.3rem;
        color: var(--mm-grey);
        font-size: 3em; }
      #opening-suggestion-list-item .info .details blockquote:before {
        content: open-quote;
        left: -3px; }
      #opening-suggestion-list-item .info .details blockquote:after {
        content: close-quote;
        left: 3px; }
    #opening-suggestion-list-item .info .already-in-map {
      font-size: 0.8rem;
      text-transform: uppercase;
      color: var(--mm-light-black); }
    #opening-suggestion-list-item .info .actions {
      flex-shrink: 1;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      flex-wrap: wrap;
      color: var(--mm-grey);
      font-size: 1.2rem;
      margin-right: 1.5rem; }
      #opening-suggestion-list-item .info .actions > * {
        margin: 0;
        min-width: 110px; }
    #opening-suggestion-list-item .info .suggester-status {
      display: flex;
      align-items: center;
      gap: 0.5rem; }
  #opening-suggestion-list-item.list-mode.hidden {
    background: var(--mm-dark-white); }
