#mapmaker-contact-center .breadcrumbs {
  margin-bottom: 2rem; }

#mapmaker-contact-center .option-grid {
  display: grid;
  grid-gap: 1rem;
  /* Min is the css min, but SASS will eat the min otherwise. */
  grid-template-columns: repeat(auto-fill, minmax(Min(400px, 100%), 1fr)); }

#mapmaker-contact-center .option {
  padding: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: 5px;
  border: 1px solid var(--light-grey);
  min-height: 100px; }
  #mapmaker-contact-center .option:hover {
    border-color: var(--link-color); }
    #mapmaker-contact-center .option:hover .optionIcon,
    #mapmaker-contact-center .option:hover .optionInfo {
      color: var(--black); }
  #mapmaker-contact-center .option .option-icon {
    flex-basis: 50px;
    color: var(--light-black); }
  #mapmaker-contact-center .option .option-info {
    color: var(--light-black); }
    #mapmaker-contact-center .option .option-info h3 {
      margin: 0; }
    #mapmaker-contact-center .option .option-info ul {
      padding-left: 1rem;
      list-style: circle; }
      #mapmaker-contact-center .option .option-info ul li {
        margin-left: 0; }
