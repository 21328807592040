#slideout-menu {
  /* Never show the slideout on larger screens */
  @media screen and (min-width: 768px) {
    display: none;
  }

  position: absolute;
  z-index: 20000;
  top: 0;
  left: 0;

  .backdrop {
    position: fixed;
    top: 0;
    bottom: -100px;
    left: 0;
    width: 100vw;
    background: #000;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s ease-out;
    pointer-events: visible;
  }
  .close-button {
    position: fixed;
    z-index: 100;
    top: 30px;
    right: 0;
    width: 90px;
    font-size: 45px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s ease-out;
    pointer-events: none;
  }

  .menu {
    position: fixed;
    z-index: 1;
    left: calc(-100vw + 90px);
    width: calc(100vw - 90px);
    height: 100vh;
    overflow-x: hidden;
    margin-top: 0;
    padding: 0em;
    padding-bottom: 2em;
    background: #fff;
    transition: left 0.1s ease-out;

    .logo {
      margin-top: 1em;
      margin-bottom: 1em;
      text-align: center;
    }

    .active > .item {
      background-color: #eee;
    }

    > .header {
      margin-left: 1em;
      margin-bottom: 0.5em;
      font-weight: bold;
    }

    .item {
      display: flex;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-top: 0.6em;
      padding-bottom: 0.6em;
      font-size: 0.9em;

      .content {
        flex-grow: 1;
      }

      .badge {
        background: var(--accent-color);
        color: var(--accent-text-color);
        padding: 4px 8px;
        border-radius: 4px;
        margin-top: -2px;
        margin-bottom: -2px;
        font-weight: bold;
        text-align: center;
      }
    }
  }

  &.open {
    .backdrop {
      visibility: visible;
      opacity: 0.7;
    }
    .close-button {
      visibility: visible;
      opacity: 1;
    }
    .menu {
      left: 0;
    }
  }
}
