#opening-images-bar .image-thumbnail {
  border: 2px solid var(--mm-black);
  border-radius: 3px;
  cursor: pointer; }
  #opening-images-bar .image-thumbnail img {
    filter: grayscale(1);
    object-fit: cover;
    opacity: 0.4; }
  #opening-images-bar .image-thumbnail.selected {
    opacity: 1;
    border-color: var(--mm-accent-color); }
    #opening-images-bar .image-thumbnail.selected img {
      opacity: 1;
      filter: none; }
  #opening-images-bar .image-thumbnail:hover:not(.selected) {
    opacity: 1;
    background: var(--mm-light-grey);
    border-color: var(--mm-grey); }
