#sticker-token-redemption-form label {
  display: block;
  margin-bottom: 4px;
  font-weight: bold; }

#sticker-token-redemption-form .input-bar {
  display: flex; }
  #sticker-token-redemption-form .input-bar:not(.inline) {
    flex-direction: column; }
    #sticker-token-redemption-form .input-bar:not(.inline).success {
      margin-bottom: 4em; }
    #sticker-token-redemption-form .input-bar:not(.inline) .submit {
      min-width: 200px;
      margin: 0 auto;
      margin-top: 2em; }
  #sticker-token-redemption-form .input-bar.success .submit {
    display: none; }
  #sticker-token-redemption-form .input-bar .input-container {
    position: relative;
    flex-grow: 1; }
    #sticker-token-redemption-form .input-bar .input-container .header {
      position: absolute;
      left: 0;
      width: 100%;
      top: 0px;
      transform: translateY(-100%); }
    #sticker-token-redemption-form .input-bar .input-container input,
    #sticker-token-redemption-form .input-bar .input-container .success {
      font-size: 1.8em;
      padding: 7px 0.5em;
      width: 100%;
      margin-right: 0;
      text-align: center; }
    #sticker-token-redemption-form .input-bar .input-container input {
      border: 1px solid var(--mm-grey); }
      #sticker-token-redemption-form .input-bar .input-container input::placeholder {
        color: var(--mm-grey); }
      #sticker-token-redemption-form .input-bar .input-container input:focus, #sticker-token-redemption-form .input-bar .input-container input.touched {
        font-family: monospace; }
      #sticker-token-redemption-form .input-bar .input-container input:focus::-webkit-input-placeholder {
        color: transparent; }
      #sticker-token-redemption-form .input-bar .input-container input:focus:-moz-placeholder {
        color: transparent; }
      #sticker-token-redemption-form .input-bar .input-container input:focus::-moz-placeholder {
        color: transparent; }
    #sticker-token-redemption-form .input-bar .input-container .message {
      position: absolute;
      left: 0;
      width: 100%;
      bottom: 0px;
      transform: translateY(100%);
      padding: 0.25em 0;
      text-align: center;
      font-size: 1.2rem; }
      #sticker-token-redemption-form .input-bar .input-container .message.error {
        color: var(--mm-error-color); }
      #sticker-token-redemption-form .input-bar .input-container .message.another {
        cursor: pointer;
        color: var(--mm-grey); }
        #sticker-token-redemption-form .input-bar .input-container .message.another span:hover {
          color: var(--mm-dark-grey); }
    #sticker-token-redemption-form .input-bar .input-container > .success {
      color: var(--mm-success-color);
      font-weight: 300; }
  #sticker-token-redemption-form .input-bar .submit {
    margin-left: 0;
    border-radius: 0; }
