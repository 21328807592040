/*-------------------
      Site Colors 
--------------------*/
/*---  Main Colors  ---*/
/*---  Colors  ---*/
/*---  Light Colors  ---*/
/*---   Neutrals  ---*/
/*--- Colored Backgrounds ---*/
/*--- Colored Text ---*/
/*--- Colored Headers ---*/
/*--- Colored Border ---*/
/*-------------------
       Layout
--------------------*/
/*-------------------
      Mapmaker 
--------------------*/
/*-------------------
      Other 
--------------------*/
#context-panel-checkbox {
  margin: 10px 0;
  border: 1px solid #DCDDDE;
  padding: 12px 10px 8px 10px;
  cursor: pointer; }
  #context-panel-checkbox:hover {
    border: 1px solid #999999; }
  #context-panel-checkbox.disabled, #context-panel-checkbox.disabled:hover {
    background: #F3F4F5;
    border: 1px solid #DCDDDE;
    cursor: auto; }
    #context-panel-checkbox.disabled .checkbox label, #context-panel-checkbox.disabled:hover .checkbox label {
      opacity: 1;
      color: #DCDDDE; }
    #context-panel-checkbox.disabled .ui.checkbox input:checked ~ label:after, #context-panel-checkbox.disabled:hover .ui.checkbox input:checked ~ label:after {
      color: #DCDDDE; }
  #context-panel-checkbox .checkbox label {
    margin-right: 5px;
    float: right; }
  #context-panel-checkbox .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: teal !important; }
  #context-panel-checkbox .icon {
    float: right;
    vertical-align: top;
    margin-top: -2px; }
