/*-------------------
      Site Colors 
--------------------*/
/*---  Main Colors  ---*/
/*---  Colors  ---*/
/*---  Light Colors  ---*/
/*---   Neutrals  ---*/
/*--- Colored Backgrounds ---*/
/*--- Colored Text ---*/
/*--- Colored Headers ---*/
/*--- Colored Border ---*/
/*-------------------
       Layout
--------------------*/
/*-------------------
      Mapmaker 
--------------------*/
/*-------------------
      Other 
--------------------*/
#rectangular-print-card {
  border-radius: 0; }
  #rectangular-print-card .image {
    border-radius: 0 !important;
    background: #DCDDDE;
    padding: 1em; }
  #rectangular-print-card .svg-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  #rectangular-print-card .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0.5em; }
    #rectangular-print-card .content .title {
      flex-grow: 1;
      margin-right: 0.5em; }
    #rectangular-print-card .content .error {
      color: #DB2828;
      text-align: center;
      margin-top: 1em;
      width: 100%; }
  #rectangular-print-card .message {
    color: #F2711C;
    cursor: pointer; }
    #rectangular-print-card .message:hover {
      text-decoration: underline; }
    #rectangular-print-card .message .icon {
      vertical-align: top;
      margin-right: 8px;
      margin-top: 3px; }
