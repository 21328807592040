.layout {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .page-container {
    display: flex;
    align-items: stretch;
    position: relative;
    flex-grow: 1;
    min-height: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}

.variable-layout {
  .page-container {
    /* This keeps the footer below the fold when there is not enough content to fill the page. */
    flex-shrink: 0;
    min-height: calc(100vh - 80px);
    @media screen and (max-width: 767px) {
      min-height: calc(100vh - 58px);
    }
  }
}

.fixed-layout {
  #nav-footer {
    display: none;
  }

  .page-container {
    overflow: auto;
    padding-bottom: 0;
    height: 100%;
  }
}

.noscroll {
  overflow: hidden;
}
