#full-map-svg {
  color-scheme: only light; }
  #full-map-svg .opening-svg-node {
    fill: none;
    stroke: none;
    pointer-events: visibleFill;
    user-select: none;
    cursor: pointer;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    stroke-linejoin: round; }
    #full-map-svg .opening-svg-node .background {
      color-scheme: only light;
      fill: #ffffff; }
    #full-map-svg .opening-svg-node .highlight {
      stroke: var(--mm-grey);
      stroke-width: 1px; }
    @media (hover) {
      #full-map-svg .opening-svg-node:hover .highlight, #full-map-svg .opening-svg-node:active .highlight {
        stroke: var(--mm-accent-color);
        stroke-width: 3px; }
      #full-map-svg .opening-svg-node:hover.has-image .highlight, #full-map-svg .opening-svg-node:active.has-image .highlight {
        fill: #fff;
        fill-opacity: 0.3; } }
    #full-map-svg .opening-svg-node.is-optional .outermost-visible-layer {
      fill: #ffffff; }
    #full-map-svg .opening-svg-node.is-optional .background {
      display: inherit;
      stroke: var(--mm-light-grey);
      stroke-width: 1px; }
    #full-map-svg .opening-svg-node.is-optional:not(.has-image) .background {
      fill: var(--mm-dark-white); }
  #full-map-svg.mode-live-with-suggestions .opening-svg-node.has-suggestions .highlight {
    fill: var(--mm-suggestion-color);
    animation: flashOpacity 1s alternate infinite;
    --start-opacity: 0;
    --end-opacity: 1;
    --start-fill: var(--mm-white);
    --end-fill: var(--mm-suggestion-color);
    opacity: --start-opacity; }
    #full-map-svg.mode-live-with-suggestions .opening-svg-node.has-suggestions .highlight:hover {
      --start-opacity: 1;
      --end-opacity: 1;
      --start-fill: var(--mm-suggestion-color);
      --end-fill: var(--mm-suggestion-color);
      stroke: var(--mm-light-black);
      stroke-width: 2; }

@keyframes flashOpacity {
  from {
    opacity: var(--start-opacity);
    fill: var(--start-fill); }
  to {
    opacity: var(--end-opacity);
    fill: var(--end-fill); } }
