#mapmaker-help-page {
  display: flex;
  flex-direction: column;
  width: 100%; }
  #mapmaker-help-page .mainContainer {
    display: flex;
    justify-content: center; }
  #mapmaker-help-page .breadcrumbContainer {
    position: relative;
    border-bottom: 1px solid var(--mm-light-grey);
    width: 100%;
    max-width: 1200px;
    margin: 0 auto; }
    @media (max-width: 768px) {
      #mapmaker-help-page .breadcrumbContainer {
        padding: 1rem; } }
    @media (min-width: 769px) {
      #mapmaker-help-page .breadcrumbContainer {
        padding: 2rem; } }
  #mapmaker-help-page .mainContainer {
    flex-grow: 1; }
  #mapmaker-help-page .main {
    display: flex;
    margin-bottom: 1rem;
    max-width: 1200px;
    flex-grow: 1; }
    @media (max-width: 768px) {
      #mapmaker-help-page .main {
        margin-top: 0; } }
    @media (min-width: 769px) {
      #mapmaker-help-page .main {
        margin-top: 1rem; } }
    #mapmaker-help-page .main .nav {
      flex-grow: 0;
      flex-shrink: 0; }
    @media (max-width: 768px) {
      #mapmaker-help-page .main {
        flex-direction: column; }
        #mapmaker-help-page .main .page {
          flex-grow: 1;
          padding: 1rem;
          min-height: 100%; } }
    @media (min-width: 769px) {
      #mapmaker-help-page .main {
        flex-direction: row;
        align-items: stretch; }
        #mapmaker-help-page .main .nav {
          flex-basis: 240px;
          border-right: 1px solid var(--mm-light-grey); }
        #mapmaker-help-page .main .page {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          padding: 1rem 2rem 0 2rem; }
          #mapmaker-help-page .main .page #sanity-navigation-sibling-links {
            flex-grow: 1;
            place-items: end; } }
