#account-sticker-tokens .header {
  display: flex;
  place-items: center;
  justify-items: center; }
  @media screen and (max-width: 1099px) {
    #account-sticker-tokens .header {
      flex-direction: column;
      padding-top: 1em; }
      #account-sticker-tokens .header .redemption-form {
        padding-top: 3em;
        padding-bottom: 1.5em; }
        #account-sticker-tokens .header .redemption-form input {
          font-size: 1.2rem; }
      #account-sticker-tokens .header > :last-child {
        flex-grow: 1; } }
  @media screen and (min-width: 1100px) {
    #account-sticker-tokens .header .redemption-form {
      padding: 0 2em; }
    #account-sticker-tokens .header > :last-child {
      flex-grow: 1;
      margin-left: 3em; } }
