#mapmaker-top-bar {
  width: 100%;
  display: flex;
  align-items: center;
  height: 56px;
  padding-right: 1rem; }
  #mapmaker-top-bar.mode-normal {
    background: var(--mm-white); }
  #mapmaker-top-bar.mode-suggestions {
    background: var(--mm-suggestion-color);
    color: var(--mm-suggestion-text-color); }
  #mapmaker-top-bar .breadcrumb {
    padding: 1em;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    user-select: none;
    min-width: 0;
    overflow: hidden; }
    #mapmaker-top-bar .breadcrumb .section {
      overflow: hidden;
      padding-bottom: 2px;
      white-space: nowrap;
      text-overflow: ellipsis;
      flex-shrink: 0; }
      #mapmaker-top-bar .breadcrumb .section:first-child {
        flex-shrink: 1; }
  #mapmaker-top-bar .editable-breadcrumb {
    width: auto;
    min-width: 0; }
    #mapmaker-top-bar .editable-breadcrumb .ghost-input,
    #mapmaker-top-bar .editable-breadcrumb input {
      width: 100%;
      text-overflow: ellipsis;
      border: none;
      padding: 0;
      margin: 0;
      white-space: pre;
      background: transparent; }
    #mapmaker-top-bar .editable-breadcrumb input {
      outline: none; }
      #mapmaker-top-bar .editable-breadcrumb input:disabled {
        background: #fff; }
      @media (hover: hover) and (pointer: fine) {
        #mapmaker-top-bar .editable-breadcrumb input:hover {
          box-shadow: 0 -1px 0 5px #fff, 0 -1px 0 6px var(--mm-light-grey); } }
      #mapmaker-top-bar .editable-breadcrumb input:focus {
        box-shadow: 0 -1px 0 5px #fff, 0 -1px 0 6px var(--mm-light-black); }
    #mapmaker-top-bar .editable-breadcrumb .ghost-input {
      visibility: hidden;
      overflow: hidden;
      letter-spacing: -0.03pt;
      margin-top: -1em;
      margin-right: 0.5em; }
