#sticker-offer-grid-item {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  -webkit-tap-highlight-color: transparent; }
  #sticker-offer-grid-item img {
    cursor: pointer;
    max-width: 100%;
    max-height: 200px;
    object-fit: contain;
    flex-grow: 1;
    transition: all 0.1s ease-in-out;
    filter: grayscale(1);
    opacity: 0.3; }
  #sticker-offer-grid-item.deselected img {
    transform: scale(0.9); }
  #sticker-offer-grid-item.selected img {
    filter: grayscale(0);
    opacity: 1; }
  #sticker-offer-grid-item .details {
    display: flex;
    place-items: center;
    margin-top: 4px;
    padding: 0.5em;
    border-radius: 3px; }
    #sticker-offer-grid-item .details.deselected {
      color: var(--mm-grey); }
    #sticker-offer-grid-item .details .qty-btn {
      cursor: pointer;
      margin: 0 0.2em;
      font-size: 3em;
      font-weight: bold;
      user-select: none; }
      @media (hover: hover) and (pointer: fine) {
        #sticker-offer-grid-item .details .qty-btn:hover {
          color: var(--mm-accent-color); } }
      #sticker-offer-grid-item .details .qty-btn:active {
        margin-top: 2px;
        margin-bottom: -2px; }
    #sticker-offer-grid-item .details .info {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center; }
      #sticker-offer-grid-item .details .info .quantity {
        cursor: pointer;
        width: 2em;
        height: 2em;
        margin-right: 0.5em;
        border-radius: 100%;
        text-align: center;
        font-size: 1.4em;
        font-weight: bold;
        line-height: 1.9em;
        box-shadow: 0 0px 2px RGBA(0, 0, 0, 0.3); }
        #sticker-offer-grid-item .details .info .quantity.selected {
          background: var(--mm-grey);
          color: var(--mm-white); }
        #sticker-offer-grid-item .details .info .quantity.deselected {
          background: var(--mm-light-grey);
          color: var(--mm-white); }
      #sticker-offer-grid-item .details .info h4 {
        font-size: 1.1em;
        margin: 0; }
      #sticker-offer-grid-item .details .info .size {
        font-size: 1em; }
