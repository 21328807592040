#order-stickers-context-panel-mobile .details-toggle {
  width: 100%;
  padding: 0.5em;
  text-align: center;
  color: var(--link-color); }

#order-stickers-context-panel-mobile > .details {
  display: none;
  padding: 1em 2em 1em 2em; }

#order-stickers-context-panel-mobile.open > .details {
  display: block; }

#order-stickers-context-panel-mobile.open .bar h1,
#order-stickers-context-panel-mobile.open .bar h4 {
  display: none; }

#order-stickers-context-panel-mobile .bar {
  text-align: center;
  padding: 2em;
  padding-top: 0.5em;
  padding-bottom: 1em; }

#order-stickers-context-panel-desktop .totals {
  padding: 2.5em 3em;
  border-bottom: 1px solid var(--mm-light-grey); }

#order-stickers-context-panel-desktop .breakdown-section {
  padding: 1em 3em; }
  #order-stickers-context-panel-desktop .breakdown-section h3 {
    text-align: center; }

#use-sticker-tokens-toggle {
  margin-top: 1em;
  font-size: 1.1em;
  cursor: pointer;
  user-select: none; }
  #use-sticker-tokens-toggle .checkbox {
    margin-top: -4px; }
  #use-sticker-tokens-toggle .sticker-token-icon {
    margin-left: 6px;
    margin-bottom: -6px; }
  #use-sticker-tokens-toggle:not(.selected) {
    color: var(--mm-grey); }
    #use-sticker-tokens-toggle:not(.selected) .sticker-token-icon {
      filter: grayscale(1);
      opacity: 0.6; }

#sticker-order-overview {
  text-align: center; }
  #sticker-order-overview #mapmaker-button {
    margin: 0; }
  #sticker-order-overview h1,
  #sticker-order-overview h4 {
    margin: 0; }
  #sticker-order-overview h1 {
    margin-bottom: 0.5em; }
  #sticker-order-overview .totals {
    flex-grow: 1;
    padding: 1em; }

#sticker-order-breakdown table {
  border-collapse: collapse;
  width: 100%;
  text-align: left; }
  #sticker-order-breakdown table th:not(:first-child),
  #sticker-order-breakdown table td:not(:first-child) {
    text-align: right; }
  #sticker-order-breakdown table .spacer {
    border-bottom: 1px solid var(--mm-light-grey); }
    #sticker-order-breakdown table .spacer td {
      padding-top: 0.5em; }
  #sticker-order-breakdown table .total-row {
    font-size: 1.1em;
    font-weight: bold; }
    #sticker-order-breakdown table .total-row td {
      padding-top: 0.5em; }

#sticker-order-breakdown .shipping-note {
  margin-top: 1em;
  color: var(--mm-light-black);
  text-align: center;
  font-weight: bold; }
  #sticker-order-breakdown .shipping-note.free {
    color: var(--mm-discount-color); }
  #sticker-order-breakdown .shipping-note > p.note {
    font-weight: normal;
    color: var(--mm-light-black);
    margin-top: 1em; }
