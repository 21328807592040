dl#standard-line-item-details > div {
  display: flex;
  width: 100%;
  margin-bottom: 0.25rem; }
  dl#standard-line-item-details > div dt {
    display: block;
    flex-basis: 107px;
    margin: 0;
    flex-shrink: 0;
    font-weight: bold; }
  dl#standard-line-item-details > div dd {
    display: block;
    flex-grow: 1;
    margin: 0;
    display: block;
    white-space: pre; }
