.gift-note-display {
  margin-bottom: 0.5rem;
  background-color: var(--dark-white);
  border-radius: 3px;
  border: 1px solid var(--light-grey); }
  .gift-note-display .header {
    display: flex;
    margin: 0;
    padding: 0.25rem;
    padding-left: 0.5rem;
    color: var(--grey);
    font-weight: bold;
    font-size: 0.8rem; }
    .gift-note-display .header span {
      flex-grow: 1; }
  .gift-note-display:not(.editing) {
    cursor: pointer; }
  .gift-note-display .edit-button {
    display: none; }
  .gift-note-display:hover .edit-button {
    display: initial; }
  .gift-note-display > .note {
    color: var(--light-black);
    padding: 0.5rem;
    padding-top: 0; }
    .gift-note-display > .note.display {
      cursor: pointer;
      white-space: pre; }
    .gift-note-display > .note textarea {
      width: 100%;
      resize: vertical;
      padding: 0.5rem; }
