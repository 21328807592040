#download-print-list-header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 1rem 2rem;
  background: var(--dark-white);
  border-bottom: 1px solid var(--mm-grey); }
  #download-print-list-header .printer {
    width: 100px; }
  @media screen and (max-width: 767px) {
    #download-print-list-header {
      gap: 1rem;
      padding: 1rem; }
      #download-print-list-header .printer {
        width: 80px; } }
