.map-setting-section:not(:first-of-type) {
  margin-top: 2rem; }

.map-setting-section > h3 {
  color: var(--mm-black);
  margin-bottom: 0; }

.map-setting-section > .description {
  font-size: 0.9rem;
  color: var(--mm-dark-grey); }
