#mapmaker-message {
  display: flex;
  padding: 1em; }
  #mapmaker-message .link {
    color: unset;
    text-decoration: underline;
    font-weight: bold; }
  #mapmaker-message.info {
    background-color: var(--mm-info-color);
    color: var(--mm-info-text-color); }
    #mapmaker-message.info a {
      color: var(--mm-white);
      text-decoration: underline; }
  #mapmaker-message.warning {
    background-color: var(--mm-warning-color);
    color: var(--mm-warning-text-color); }
  #mapmaker-message.error {
    background-color: var(--mm-error-color);
    color: var(--mm-error-text-color); }
    #mapmaker-message.error a {
      color: var(--mm-white);
      text-decoration: underline; }
  #mapmaker-message .content {
    flex-grow: 1;
    padding: 0 0.5em; }
  #mapmaker-message .close {
    cursor: pointer;
    font-size: 1.5em;
    user-select: none; }
    #mapmaker-message .close:hover {
      font-weight: bold; }
    #mapmaker-message .close:active {
      margin-top: 1px;
      margin-bottom: -1px; }
