/*-------------------
      Site Colors 
--------------------*/
/*---  Main Colors  ---*/
/*---  Colors  ---*/
/*---  Light Colors  ---*/
/*---   Neutrals  ---*/
/*--- Colored Backgrounds ---*/
/*--- Colored Text ---*/
/*--- Colored Headers ---*/
/*--- Colored Border ---*/
/*-------------------
       Layout
--------------------*/
/*-------------------
      Mapmaker 
--------------------*/
/*-------------------
      Other 
--------------------*/
#walgreens-store-list-item {
  text-align: left;
  font-size: 11pt;
  border-bottom: 1px solid #DCDDDE; }
  #walgreens-store-list-item:hover {
    background: #F9FAFB; }
  #walgreens-store-list-item:last-child {
    border-bottom: none; }
  #walgreens-store-list-item p {
    margin: 0; }
  #walgreens-store-list-item .head {
    color: #e51937;
    padding-bottom: 0.5em;
    cursor: pointer; }
    #walgreens-store-list-item .head:hover > .header {
      text-decoration: underline; }
    #walgreens-store-list-item .head .header {
      color: #e51937;
      font-size: 14pt;
      font-weight: normal;
      margin-bottom: 0; }
    #walgreens-store-list-item .head .distance {
      display: block;
      font-size: 12pt;
      margin-top: -3px; }
  #walgreens-store-list-item label {
    display: inline-block;
    font-weight: normal;
    font-size: 10pt;
    min-width: 100px;
    cursor: pointer; }
  #walgreens-store-list-item .case-fix {
    text-transform: capitalize; }
  #walgreens-store-list-item .select {
    text-align: center; }
  @media screen and (min-width: 768px) {
    #walgreens-store-list-item:hover .button {
      background: #e51937; } }
  @media screen and (max-width: 767px) {
    #walgreens-store-list-item .address {
      margin-top: 1em; }
    #walgreens-store-list-item .select {
      margin-top: 1em; } }
