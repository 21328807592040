svg #editable-opening-image .selected-overlay {
  stroke: var(--mm-accent-color);
  stroke-width: 2; }

svg #editable-opening-image.highlighted {
  filter: brightness(1); }

svg #editable-opening-image.lowlighted {
  opacity: 0.2; }

#opening-editor-svg-container .tbl-moveable {
  z-index: 1; }
  #opening-editor-svg-container .tbl-moveable .moveable-control {
    width: 16px;
    height: 16px;
    margin-top: -8px;
    margin-left: -8px; }
  #opening-editor-svg-container .tbl-moveable .moveable-control:hover,
  #opening-editor-svg-container .tbl-moveable .moveable-control:active {
    filter: brightness(1.2); }
  #opening-editor-svg-container .tbl-moveable .moveable-control:after {
    content: "";
    width: 30px;
    height: 30px;
    border-radius: 20px;
    display: block;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: relative; }
  #opening-editor-svg-container .tbl-moveable .moveable-line {
    height: 2px; }
    #opening-editor-svg-container .tbl-moveable .moveable-line.moveable-bold {
      width: 3px; }
  #opening-editor-svg-container .tbl-moveable .moveable-rotation {
    height: 30px; }
    #opening-editor-svg-container .tbl-moveable .moveable-rotation .moveable-line.moveable-rotation-line {
      height: 30px; }
    #opening-editor-svg-container .tbl-moveable .moveable-rotation .moveable-line.moveable-rotation-line .moveable-control {
      border-color: #4af;
      background: #fff;
      cursor: alias; }

.mobile #opening-editor-svg-container .tbl-moveable .moveable-control {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px; }
