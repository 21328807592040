.footer {
  font-size: 1.2rem;
  text-align: center;

  /* Mobile */
  @media screen and (max-width: 768px) {
    margin: 1rem 0;
  }

  /* Non-card page styles */
  @media screen and (max-width: 1199px) {
    margin: 5rem 0;
    font-size: 1.3rem;
  }
}
