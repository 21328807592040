#walgreens-print-list-header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 1rem 2rem;
  background: var(--dark-white);
  border-bottom: 1px solid var(--mm-grey); }
  #walgreens-print-list-header .walgreens-logo {
    width: 100px;
    flex-grow: 0; }
  @media screen and (max-width: 767px) {
    #walgreens-print-list-header {
      gap: 1rem;
      padding: 1rem;
      font-size: 0.9rem; } }
