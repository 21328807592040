#sandbox-panel {
  min-width: 360px; }
  #sandbox-panel section h3 {
    margin: 0;
    padding: 1rem;
    width: 100%; }
  #sandbox-panel section .content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem; }
  #sandbox-panel section #renderable-props-form {
    display: flex;
    flex-direction: column;
    gap: 1rem; }
  #sandbox-panel section .render-type-select {
    width: 100%;
    padding: 0.5rem; }
