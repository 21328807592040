#output-scale-settings .alert {
  display: flex;
  align-items: center;
  gap: 1rem;
  background: var(--mm-info-color);
  color: var(--mm-info-text-color);
  border: 1px solid;
  padding: 1rem;
  border-radius: 5px; }
  #output-scale-settings .alert .info {
    flex-grow: 1; }
  #output-scale-settings .alert .link {
    color: var(--mm-white);
    font-weight: bold; }
