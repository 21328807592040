#account-general-settings .details {
  display: flex; }
  @media screen and (max-width: 767px) {
    #account-general-settings .details {
      flex-direction: column; } }
  @media screen and (min-width: 768px) {
    #account-general-settings .details {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem; }
      #account-general-settings .details > * {
        flex-basis: calc(50% - 0.5rem); } }

#account-general-settings .label-value {
  width: 100%;
  padding-bottom: 2rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--mm-light-grey); }
  #account-general-settings .label-value .label {
    font-weight: bold; }
  #account-general-settings .label-value .value {
    color: var(--mm-dark-grey);
    min-height: 2.5rem; }

#account-general-settings .change {
  display: inline-block;
  margin-left: 0.5rem; }
