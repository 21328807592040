/*-------------------
      Site Colors 
--------------------*/
/*---  Main Colors  ---*/
/*---  Colors  ---*/
/*---  Light Colors  ---*/
/*---   Neutrals  ---*/
/*--- Colored Backgrounds ---*/
/*--- Colored Text ---*/
/*--- Colored Headers ---*/
/*--- Colored Border ---*/
/*-------------------
       Layout
--------------------*/
/*-------------------
      Mapmaker 
--------------------*/
/*-------------------
      Other 
--------------------*/
.rectangular-print-list-group-header {
  margin: 3em 0;
  position: relative;
  height: 1em;
  text-align: center;
  bottom: 0.5em; }
  .rectangular-print-list-group-header .divider {
    position: absolute;
    top: 30px;
    width: 100%;
    border-bottom: 1px solid #999999; }
  .rectangular-print-list-group-header .banner {
    position: relative;
    display: inline-block;
    background: #fff;
    z-index: 10;
    padding: 0 1em; }
  .rectangular-print-list-group-header .header {
    font-size: 27pt;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 10px; }
  .rectangular-print-list-group-header .subheader {
    text-transform: none;
    font-weight: normal; }
  @media screen and (max-width: 767px) {
    .rectangular-print-list-group-header {
      text-align: center; } }
