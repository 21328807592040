.mapmaker-print-service-selector {
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
  gap: 2rem;
  width: 100%;
  overflow-y: auto; }
  .mapmaker-print-service-selector > * {
    flex-shrink: 0; }
  .mapmaker-print-service-selector .message {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    background: var(--mm-dark-white);
    padding: 2rem 1rem; }
    .mapmaker-print-service-selector .message > svg {
      color: var(--mm-dark-grey);
      flex-shrink: 1; }
    .mapmaker-print-service-selector .message > div {
      flex-shrink: 3;
      max-width: 600px;
      color: var(--mm-light-black); }
      .mapmaker-print-service-selector .message > div h3 {
        margin-bottom: 0.25rem; }
  .mapmaker-print-service-selector .header {
    text-align: center; }
    .mapmaker-print-service-selector .header:first-child {
      margin-top: 3rem; }
  .mapmaker-print-service-selector .cards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    width: 100%;
    flex-grow: 1; }
  .mapmaker-print-service-selector .card {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 350px;
    min-height: 480px;
    box-shadow: 0 1px 5px #00000044, 0px 15px 25px #00000006;
    text-align: center;
    padding: 0 2rem;
    cursor: pointer; }
    .mapmaker-print-service-selector .card:hover {
      box-shadow: 0 1px 5px #00000088, 0px 15px 25px #00000006; }
      .mapmaker-print-service-selector .card:hover button {
        background: var(--mm-accent-color);
        color: var(--mm-accent-text-color); }
    .mapmaker-print-service-selector .card:active {
      margin-bottom: -4px; }
    .mapmaker-print-service-selector .card #ribbon {
      position: absolute;
      left: -1rem;
      top: 0.5rem; }
    .mapmaker-print-service-selector .card .image {
      margin: 0 auto;
      background: var(--mm-white);
      width: 100%;
      height: 200px;
      object-fit: contain;
      object-position: 50% 50%; }
    .mapmaker-print-service-selector .card > .content {
      flex-grow: 1; }
      .mapmaker-print-service-selector .card > .content .description {
        text-align: center;
        color: var(--mm-light-black);
        margin: 0; }
    .mapmaker-print-service-selector .card > .extra {
      margin-bottom: 1rem; }
    .mapmaker-print-service-selector .card .button {
      box-shadow: none; }
  .mapmaker-print-service-selector .card:hover {
    transform: none; }
    .mapmaker-print-service-selector .card:hover .button {
      font-weight: bold; }
