#nav-footer {
  padding: 3em;

  a:hover {
    color: #87cd21 !important;
  }

  .lists {
    text-align: left;
    @media screen and (max-width: 767px) {
      text-align: center;
    }
  }

  .bottom-list {
    margin-top: 4em;
    > * {
      margin-bottom: 3em;
    }
    > *:last-child {
      margin-bottom: 0em;
    }
  }

  .copyright {
    font-size: 0.9em;
    color: #999;
  }
}
