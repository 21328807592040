#instructional-steps {
  display: flex;
  width: 100%; }
  #instructional-steps > div > p {
    position: relative;
    padding: 0 calc(36px + 1em); }
    #instructional-steps > div > p .number {
      position: absolute;
      top: 0;
      left: 0;
      background: var(--mm-accent-color);
      color: var(--mm-white);
      width: 36px;
      height: 36px;
      padding-top: 8px;
      font-size: 1.6em;
      border-radius: 100px; }
  @media screen and (max-width: 767px) {
    #instructional-steps {
      flex-direction: column; }
      #instructional-steps > div img {
        display: block;
        width: 80%;
        margin: 1em auto; }
      #instructional-steps > div p {
        text-align: center;
        margin-bottom: 2em; }
        #instructional-steps > div p .number {
          top: 50%;
          transform: translateY(-50%); } }
  @media screen and (min-width: 768px) {
    #instructional-steps {
      flex-wrap: wrap;
      justify-content: center; }
      #instructional-steps > div {
        display: flex;
        align-items: stretch;
        flex-basis: 50%;
        flex-direction: column;
        flex-shrink: 1;
        margin-top: 1em;
        margin-bottom: 1em; } }
    @media screen and (min-width: 768px) and (min-width: 1100px) {
      #instructional-steps > div {
        flex-basis: 33%; } }
  @media screen and (min-width: 768px) {
        #instructional-steps > div p {
          min-height: 100px;
          text-align: center;
          margin-top: 1em; }
        #instructional-steps > div img {
          display: block;
          width: 60%;
          flex-grow: 1;
          margin: 0 auto;
          object-fit: contain;
          object-position: center; } }
