svg .editable-opening {
  stroke-width: 1px;
  stroke: var(--mm-grey);
  pointer-events: visibleFill;
  user-select: none;
  touch-action: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none; }
  svg .editable-opening.has-image {
    fill: none;
    pointer-events: auto;
    cursor: move; }
  svg .editable-opening.no-image {
    fill: none;
    pointer-events: visibleFill;
    cursor: pointer; }
    svg .editable-opening.no-image .inner {
      fill: var(--mm-accent-color); }
    svg .editable-opening.no-image.has-own-cut-layer .cut {
      fill: var(--mm-accent-color); }
    svg .editable-opening.no-image.has-own-cut-layer .inner {
      fill: none; }
    svg .editable-opening.no-image image {
      touch-action: none; }
  svg .editable-opening .outline-a,
  svg .editable-opening .outline-b {
    pointer-events: none;
    transition: stroke, fill 0.24s ease-in-out;
    vector-effect: non-scaling-stroke;
    stroke-width: 2; }
  svg .editable-opening:not(.is-outlined) .outline-a {
    stroke: #666666; }
  svg .editable-opening:not(.is-outlined) .outline-b {
    stroke: #ffffff;
    stroke-dasharray: 4, 5; }
  svg .editable-opening.is-outlined .outline-a {
    stroke: #666666; }
  svg .editable-opening.is-outlined .outline-b {
    display: none; }

svg.file-dragging-over-element .editable-opening .outline-a {
  stroke: var(--mm-accent-color); }

svg.file-dragging-over-element .editable-opening .outline-b {
  fill: var(--mm-accent-color);
  fill-opacity: 0.2; }

.mobile svg .opening.unfocused:hover .inner {
  fill: none; }

.mobile svg .opening.unfocused.has-own-cut-layer:hover .inner,
.mobile svg .opening.unfocused.has-own-cut-layer:hover .cut {
  fill: none; }
