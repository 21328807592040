#opening-suggestion-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  align-items: center;
  justify-content: center; }
  @media screen and (max-width: 767px) {
    #opening-suggestion-list {
      grid-template-columns: repeat(auto-fill, 100%);
      gap: 0.5rem;
      background: var(--mm-light-grey);
      padding-top: 0.5rem;
      padding-bottom: 1rem; } }
  @media screen and (min-width: 768px) {
    #opening-suggestion-list {
      padding: 1rem;
      gap: 1rem;
      grid-template-columns: repeat(auto-fill, minmax(450px, 1fr)); } }
