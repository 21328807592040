.mapmaker-toolbar {
  background: var(--mm-black);
  display: flex;
  align-items: stretch; }
  @media screen and (min-width: 768px) {
    .mapmaker-toolbar {
      flex-direction: column;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      min-width: 50px;
      height: 100%;
      border-left: 1px solid var(--light-black); }
      .mapmaker-toolbar > * {
        min-height: 32px; }
      .mapmaker-toolbar .spacer {
        flex-grow: 1; } }
  @media screen and (max-width: 767px) {
    .mapmaker-toolbar {
      flex-direction: row;
      justify-content: space-around; }
      .mapmaker-toolbar .spacer {
        display: none; } }
