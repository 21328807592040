#background-settings > .content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(128px, 1fr));
  gap: 0.5rem; }

#background-settings .option {
  line-height: 0;
  padding: 0.5rem;
  position: relative; }
  #background-settings .option img {
    width: 100%; }
  #background-settings .option:not(.selected) {
    opacity: 0.4; }
  #background-settings .option:not(.selected):hover {
    opacity: 1; }
  #background-settings .option .name {
    bottom: 1rem;
    text-align: center;
    line-height: normal;
    padding: 3px;
    font-size: 0.9rem; }
  #background-settings .option.selected > .name {
    color: var(--mm-accent-color); }
