#line-item {
  display: flex;
  margin-bottom: 1em;
  padding: 1rem;
  position: relative;
  border-bottom: 1px solid var(--grey); }
  #line-item:last-of-type {
    border-bottom: none; }
  #line-item .thumbnail {
    margin-right: 1em; }
    #line-item .thumbnail > img {
      width: 160px; }
  @media (max-width: 767px) {
    #line-item {
      padding-left: 0rem;
      padding-right: 0rem;
      flex-direction: column; }
      #line-item .thumbnail {
        text-align: center;
        margin: 0; }
        #line-item .thumbnail > img {
          width: 80%; } }
  #line-item > .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column; }
    #line-item > .content > .header {
      display: flex;
      margin-bottom: 0.5em; }
      #line-item > .content > .header .title {
        flex-grow: 1;
        font-size: 1.4em;
        font-weight: 600; }
        #line-item > .content > .header .title .subtle-link:hover {
          cursor: pointer;
          text-decoration: underline; }
      #line-item > .content > .header .remove-btn {
        font-size: 2em;
        color: var(--grey); }
    #line-item > .content .main {
      flex-grow: 1; }
    #line-item > .content .price {
      flex-shrink: 0;
      font-size: 1.2em; }
      #line-item > .content .price .total-price.has-discounts {
        text-decoration: line-through; }
      #line-item > .content .price .discounted-price {
        margin-left: 0.5em;
        font-weight: bold;
        color: var(--discount-color); }
      #line-item > .content .price .discount-application-indicator {
        margin-left: 0.5em; }
    #line-item > .content label {
      display: inline-block;
      margin-right: 0.5em;
      font-weight: bold;
      min-width: 120px; }
    #line-item > .content select {
      cursor: pointer;
      margin-left: -4px;
      padding: 2px 4px 2px 0;
      border: none;
      border-color: var(--grey);
      border-radius: 0;
      text-align: left; }
  #line-item.loading > * {
    opacity: 0.1; }
  #line-item.loading .loader {
    opacity: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  #line-item.removed {
    opacity: 0.3; }
  #line-item .remove {
    text-align: right; }
    #line-item .remove > span {
      text-transform: uppercase;
      font-size: 0.8em;
      color: var(--grey);
      cursor: pointer; }
      #line-item .remove > span:hover {
        color: var(--dark-grey); }
