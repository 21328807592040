.mapmaker-finder-output-type-selection h2 {
  margin-bottom: 2rem; }

.mapmaker-finder-output-type-selection p {
  font-size: 1.1rem;
  color: var(--mm-dark-grey); }

.mapmaker-finder-output-type-selection .output-options {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  user-select: none;
  cursor: pointer;
  margin-bottom: 2rem; }
  .mapmaker-finder-output-type-selection .output-options .option {
    padding: 1rem;
    padding-top: 2rem;
    text-align: center;
    border-radius: 4px;
    border: 1px solid var(--mm-grey);
    user-select: none; }
    .mapmaker-finder-output-type-selection .output-options .option p {
      color: var(--mm-dark-grey); }
    .mapmaker-finder-output-type-selection .output-options .option:hover {
      border-color: var(--mm-dark-grey);
      background-color: var(--dark-white); }
    .mapmaker-finder-output-type-selection .output-options .option.selected {
      border-color: var(--mm-accent-color);
      background: var(--mm-accent-color-light); }
    .mapmaker-finder-output-type-selection .output-options .option:not(.selected) img {
      opacity: 0.7; }
