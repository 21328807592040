#feature-exit-button {
  position: absolute;
  z-index: 5;
  right: 1rem;
  top: 1rem;
  width: 2.5rem;
  aspect-ratio: 1; }
  #feature-exit-button .icon {
    transform: rotate(45deg);
    font-size: 14pt; }
