.mapmaker-sandbox {
  display: flex;
  width: 100%; }
  .mapmaker-sandbox > #main-and-nav {
    width: 100%; }
    .mapmaker-sandbox > #main-and-nav > #main {
      display: flex;
      height: 100%;
      width: 100%; }
      .mapmaker-sandbox > #main-and-nav > #main > #content {
        display: flex;
        flex-grow: 1;
        overflow: auto; }
