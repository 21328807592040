.opening-what-will-print-modal > .icon {
  right: 12px;
  top: 12px;
  color: black;
  font-size: 1.5em; }

.opening-what-will-print-modal .content {
  padding-top: 12px; }

.opening-what-will-print-modal #wwp-section .text {
  margin-top: 1em;
  min-height: 110px; }

.opening-what-will-print-modal #wwp-section .next {
  float: right; }

.opening-what-will-print-modal #wwp-section a {
  user-select: none; }

@media screen and (max-width: 767px) {
  .opening-what-will-print-modal .content {
    padding-top: 0 !important; }
  .opening-what-will-print-modal #wwp-section .text {
    min-height: 160px; } }
