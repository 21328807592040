#product-page-option {
  display: flex;
  margin: 0;
  align-items: center;
  margin-bottom: var(--option-gap);
  gap: var(--label-gap);
  font-weight: bold;
  width: 100%; }
  #product-page-option label {
    flex-grow: 0;
    flex-shrink: 0;
    width: var(--label-width);
    text-transform: uppercase;
    word-wrap: break-word;
    hyphens: auto; }
    @media screen and (min-width: 768px) {
      #product-page-option label {
        font-size: 0.9rem; } }
    @media screen and (max-width: 767px) {
      #product-page-option label {
        font-size: 0.8rem; } }
  #product-page-option input {
    flex-grow: 1; }
