#optional-opening-toggles .option {
  display: flex;
  align-items: center;
  gap: 1em;
  overflow: hidden;
  margin-top: 0.5rem;
  padding: 0.5em 1em; }
  #optional-opening-toggles .option .details {
    flex-grow: 1; }
  #optional-opening-toggles .option svg .preview {
    fill: var(--mm-grey); }
  #optional-opening-toggles .option .icon {
    color: var(--mm-light-grey); }
  #optional-opening-toggles .option:not(.selected):hover .icon {
    color: var(--mm-light-black); }
  #optional-opening-toggles .option:not(.selected):hover svg .preview {
    fill: var(--mm-light-black); }
  #optional-opening-toggles .option.selected .icon {
    color: var(--mm-accent-color); }
  #optional-opening-toggles .option.selected .preview {
    fill: var(--mm-light-black); }
