#stickers-line-item #sticker-thumbnails {
  position: relative;
  min-height: 64px;
  margin: 1em 0; }
  #stickers-line-item #sticker-thumbnails .note {
    color: var(--light-black);
    font-size: 0.85em;
    margin-top: 1em; }
  #stickers-line-item #sticker-thumbnails > .sticker-thumbnail {
    position: relative;
    display: inline-block;
    padding-bottom: 1em; }
    #stickers-line-item #sticker-thumbnails > .sticker-thumbnail img {
      min-width: 64px;
      min-height: 64px; }
    #stickers-line-item #sticker-thumbnails > .sticker-thumbnail .qty {
      position: absolute;
      z-index: 10;
      padding: 3px 5px;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background: var(--accent-text-color);
      color: var(--accent-color);
      font-weight: bold;
      font-size: 0.9em;
      border-radius: 2em;
      border: 1px solid;
      box-shadow: 0 2px 3px RGBA(0, 0, 0, 0.5); }

#stickers-line-item .no-sticker-info {
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  margin: 1rem 0;
  background-color: var(--mm-dark-white); }
  #stickers-line-item .no-sticker-info svg {
    font-size: 26pt;
    color: var(--mm-warning-color); }
  #stickers-line-item .no-sticker-info > p {
    flex-grow: 1; }
