#walgreens-print-list-footer {
  padding-bottom: 1em; }
  #walgreens-print-list-footer .action {
    margin-top: 2em;
    margin-bottom: 3em;
    text-align: center; }
    #walgreens-print-list-footer .action .status {
      margin-top: 1em; }
    #walgreens-print-list-footer .action .error {
      color: var(--mm-error-color); }
    #walgreens-print-list-footer .action .button {
      margin: 0; }
