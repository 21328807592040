#walgreens-order-review {
  text-align: left;
  padding-bottom: 3em; }
  #walgreens-order-review .centered {
    text-align: center; }
  #walgreens-order-review .error {
    font-size: 12pt;
    background: var(--mm-error-color);
    color: var(--mm-error-text-color);
    padding: 1em; }

#walgreens-order-error {
  text-align: center;
  padding-top: 1rem;
  color: var(--mm-error-color); }
  #walgreens-order-error .message {
    margin-bottom: 2rem; }
