#sharing-settings .access-policy-option {
  display: flex;
  overflow: hidden;
  padding: 1em;
  padding-left: 0; }
  #sharing-settings .access-policy-option:not(.selected):not(.disabled):hover > .icon {
    color: var(--mm-light-black); }
  #sharing-settings .access-policy-option.selected:not(.disabled) > .icon {
    color: var(--mm-accent-color); }
  #sharing-settings .access-policy-option:not(:last-child) {
    margin-bottom: 1em; }
  #sharing-settings .access-policy-option > .icon {
    color: var(--mm-light-grey);
    place-self: center;
    flex-basis: 60px;
    flex-grow: 0;
    flex-shrink: 0; }
  #sharing-settings .access-policy-option > div {
    overflow: hidden;
    width: 100%; }
    #sharing-settings .access-policy-option > div h4 {
      margin-bottom: 0; }
    #sharing-settings .access-policy-option > div > .content {
      color: var(--mm-dark-grey); }
  #sharing-settings .access-policy-option .sharing-link {
    margin-top: 1rem; }
  #sharing-settings .access-policy-option.unauthenticated .authenticated-only {
    display: none; }
  #sharing-settings .access-policy-option.authenticated .unauthenticated-only {
    display: none; }

#sharing-settings .warning {
  color: var(--mm-warning-color);
  margin-top: 0.5em; }

#sharing-settings .invite-button {
  margin-top: 0.5rem; }

#sharing-settings .access-policy-option:not(.selected) .visible-when-selected {
  display: none; }
