#mapmaker-sticker-sheets {
  width: 100%;
  background: var(--mm-dark-grey);
  padding: 2rem;
  overflow-y: auto; }

#mapmaker-sticker-sheet-group {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: center; }
  #mapmaker-sticker-sheet-group:not(:first-of-type) {
    margin-top: 2rem; }
  #mapmaker-sticker-sheet-group .group-details {
    background: var(--mm-dark-white);
    border: 1px solid var(--mm-dark-grey);
    padding: 1rem;
    box-shadow: 0 0 5px RGBA(0, 0, 0, 0.5);
    min-width: 300px; }
    #mapmaker-sticker-sheet-group .group-details .datum label {
      display: inline-block;
      min-width: 120px;
      font-weight: bold;
      font-size: 0.9rem;
      text-transform: uppercase; }
  #mapmaker-sticker-sheet-group .sheet {
    display: inline-block;
    border: 1px solid #aaa;
    box-shadow: 0 2px 16px #00000088;
    background: var(--mm-white);
    line-height: 0; }
