#sticker-token-balance {
  display: inline-flex;
  border: 1px solid var(--mm-light-grey);
  background: var(--mm-dark-white);
  padding: 1em;
  border-radius: 5em;
  min-width: 250px; }
  #sticker-token-balance > .text {
    display: flex;
    flex-direction: column;
    place-items: center;
    flex-grow: 1; }
    #sticker-token-balance > .text .label {
      text-transform: uppercase;
      font-size: 0.8em;
      color: var(--mm-dark-grey);
      margin-bottom: 4px; }
    #sticker-token-balance > .text .balance {
      font-size: 2em;
      vertical-align: middle;
      font-weight: bold; }
      #sticker-token-balance > .text .balance .loading {
        display: inline-block;
        color: var(--mm-grey);
        font-weight: normal; }
  #sticker-token-balance > .icon {
    margin-left: 1em; }
