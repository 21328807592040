#mapmaker-account-page {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%; }
  #mapmaker-account-page > .breadcrumbs {
    display: flex;
    align-items: center;
    gap: 1rem; }
    #mapmaker-account-page > .breadcrumbs .user-icon {
      font-size: 2rem; }
    #mapmaker-account-page > .breadcrumbs .account-email {
      font-size: 0.9rem;
      color: var(--mm-dark-grey); }
  #mapmaker-account-page > .content {
    flex-grow: 1; }
  #mapmaker-account-page > .bottom {
    text-align: center; }

#mapmaker-account-page-options {
  display: grid;
  grid-gap: 1rem;
  /* Min is the css min, but SASS will eat the "min" otherwise. */
  grid-template-columns: repeat(auto-fill, minmax(Min(400px, 100%), 1fr)); }
  #mapmaker-account-page-options .option {
    display: flex;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
    cursor: pointer;
    color: var(--mm-black);
    border: 1px solid var(--mm-grey);
    border-radius: 3px; }
    #mapmaker-account-page-options .option:hover {
      border-color: var(--mm-black); }
    #mapmaker-account-page-options .option .icon {
      font-size: 3rem; }
    #mapmaker-account-page-options .option .content {
      color: var(--mm-grey); }
      #mapmaker-account-page-options .option .content h4 {
        color: var(--mm-black);
        margin-bottom: 0.25rem; }
