.opening-image-info-modal .image-container {
  position: relative;
  text-align: center;
  background: var(--mm-light-grey);
  padding: 0.5em; }
  .opening-image-info-modal .image-container img {
    height: 200px;
    object-fit: contain; }
    @media screen and (min-width: 768px) {
      .opening-image-info-modal .image-container img {
        max-width: calc(100% - 4em); } }
    @media screen and (max-width: 767px) {
      .opening-image-info-modal .image-container img {
        max-width: calc(100% - 2em); } }
  .opening-image-info-modal .image-container .arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    cursor: pointer; }
    .opening-image-info-modal .image-container .arrow:hover {
      color: var(--mm-light-black); }
    @media screen and (min-width: 768px) {
      .opening-image-info-modal .image-container .arrow {
        font-size: 1.5rem; }
        .opening-image-info-modal .image-container .arrow.left {
          left: 0;
          padding: 1em 1em 1em 0.5em; }
        .opening-image-info-modal .image-container .arrow.right {
          right: 0;
          padding: 1em 0.5em 1em 1em; } }
    @media screen and (max-width: 767px) {
      .opening-image-info-modal .image-container .arrow {
        font-size: 1.2rem; }
        .opening-image-info-modal .image-container .arrow.left {
          left: 0;
          padding: 1em 1em 1em 0.1em; }
        .opening-image-info-modal .image-container .arrow.right {
          right: 0;
          padding: 1em 0.1em 1em 1em; } }

/* Need namespace with ID to adjust modal padding */
#mapmaker-modal.opening-image-info-modal > .content {
  padding: 0; }

#mapmaker-modal.opening-image-info-modal .details-container {
  padding: 1em; }
