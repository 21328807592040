.container {
  display: flex;
  gap: 1rem; }
  @media screen and (max-width: 767px) {
    .container {
      flex-direction: column; } }
  @media screen and (min-width: 768px) {
    .container {
      flex-direction: row-reverse; }
      .container .mainImage {
        flex-grow: 1; } }

.productImage {
  width: 100%;
  max-height: Max(85vh, 600px);
  object-fit: contain; }

.mainImage {
  display: flex;
  align-items: center; }
  @media screen and (min-width: 1001px) {
    .mainImage img {
      padding: 1rem;
      background: var(--mm-white);
      border: 1px solid var(--mm-light-grey);
      min-width: 100%;
      min-height: 95%; } }
  @media screen and (min-width: 1001px) and (max-width: 1100px) {
    .mainImage img {
      padding: 0rem; } }
  .mainImage > * > * {
    padding: 0 1rem; }

.thumbnails {
  display: flex; }
  @media screen and (max-width: 767px) {
    .thumbnails {
      padding: 0 1rem;
      flex-direction: row;
      overflow-x: auto;
      gap: 3px; }
      .thumbnails img {
        width: 70px;
        height: 70px;
        min-width: 70px; } }
  @media screen and (min-width: 768px) {
    .thumbnails {
      flex-shrink: 0;
      flex-direction: column;
      overflow-y: auto;
      max-height: Max(70vh, 600px);
      gap: 3px; }
      .thumbnails img {
        background: var(--mm-white);
        border: 1px solid var(--mm-light-grey);
        width: 100px;
        height: 100px;
        min-height: 70px; } }
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    .thumbnails img {
      width: 70px;
      height: 70px;
      min-height: 40px; } }
  .thumbnails::-webkit-scrollbar {
    height: 0;
    /* Remove scrollbar space */
    width: 0;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */ }
  .thumbnails img {
    object-fit: cover;
    padding: 3px;
    border: 1px solid var(--dark-white);
    border-radius: 2px;
    cursor: pointer; }
    .thumbnails img:hover {
      border: 1px solid var(--grey); }
    .thumbnails img.selected {
      border: 1px solid var(--light-black); }
