#print-service-settings .option {
  padding: 1rem;
  display: flex;
  overflow: hidden;
  padding: 1em;
  gap: 1rem; }
  #print-service-settings .option .icon {
    width: 50px;
    height: 50px;
    object-fit: contain;
    transition: opacity 0.3s; }
  #print-service-settings .option:not(.selected):not(:hover) .icon {
    opacity: 0.7;
    filter: grayscale(1); }
  #print-service-settings .option:not(last-of-type) {
    margin-top: 1rem; }
