.mapmaker-toolbar-button {
  position: relative;
  display: flex;
  flex-direction: column;
  place-items: center;
  justify-content: center;
  color: var(--mm-dark-white);
  cursor: pointer;
  padding: 0.75rem 0.5rem;
  /* Default treatment for popover children - but the children can adjust themselves if desired. */ }
  .mapmaker-toolbar-button .icon {
    margin: 0; }
  .mapmaker-toolbar-button .label {
    margin-top: 7pt;
    margin-bottom: -5px;
    line-height: 1;
    min-width: 40px;
    max-width: 80px;
    text-align: center; }
  .mapmaker-toolbar-button.selected > .badge {
    display: none; }
  .mapmaker-toolbar-button > .badge {
    position: absolute;
    z-index: 1;
    font-size: 1.5rem;
    pointer-events: none; }
    @media screen and (min-width: 768px) {
      .mapmaker-toolbar-button > .badge {
        right: 11%;
        top: 11%;
        transform: translate(50%, -50%); } }
    @media screen and (max-width: 767px) {
      .mapmaker-toolbar-button > .badge {
        right: 0;
        top: 0;
        transform: translate(50%, -50%); } }
  .mapmaker-toolbar-button:not(.disabled):not(.open):not(.selected):hover {
    background: var(--mm-light-black); }
  .mapmaker-toolbar-button.open, .mapmaker-toolbar-button.selected {
    background: var(--mm-white); }
    .mapmaker-toolbar-button.open > .icon,
    .mapmaker-toolbar-button.open > .label, .mapmaker-toolbar-button.selected > .icon,
    .mapmaker-toolbar-button.selected > .label {
      color: var(--mm-light-black); }
  .mapmaker-toolbar-button.disabled {
    cursor: default; }
    .mapmaker-toolbar-button.disabled > .icon {
      color: var(--mm-grey); }
  .mapmaker-toolbar-button:hover > .tooltip {
    transition-delay: 0.5s;
    opacity: 1;
    visibility: visible; }
  .mapmaker-toolbar-button > .tooltip {
    transition: 0.1s;
    opacity: 0;
    visibility: hidden;
    z-index: 1005;
    pointer-events: none;
    user-select: none;
    /* Alignment */
    position: absolute;
    /* Style */
    background: var(--mm-light-black);
    color: var(--mm-dark-white);
    padding: 0.5em;
    white-space: nowrap;
    border-radius: 4px;
    /* Fade in so it doesn't show on accidental mouse overs */ }
    @media screen and (min-width: 768px) {
      .mapmaker-toolbar-button > .tooltip {
        top: 50%;
        right: 0;
        transform: translate(calc(100% + 1rem), -50%); } }
    @media screen and (max-width: 767px) {
      .mapmaker-toolbar-button > .tooltip {
        top: 0;
        left: 50%;
        transform: translate(-50%, calc(-100% - 1.5em)); } }
  .mapmaker-toolbar-button.popover.open .popover-content > * {
    position: absolute;
    z-index: 10; }
  @media screen and (min-width: 768px) {
    .mapmaker-toolbar-button.popover.open .popover-content > * {
      left: 10px;
      top: 50%;
      transform: translate(45px, -50%); }
    .mapmaker-toolbar-button.popover.open:first-of-type:not(:only-of-type) > .popover-content > * {
      top: 0;
      bottom: initial;
      transform: translate(45px, 0); }
    .mapmaker-toolbar-button.popover.open:last-of-type:not(:only-of-type):not(:nth-of-type(2)) > .popover-content
> * {
      bottom: 0;
      top: initial;
      transform: translate(45px, 0); } }
  @media screen and (max-width: 767px) {
    .mapmaker-toolbar-button.popover.open .popover-content > * {
      top: 0;
      left: 50%;
      transform: translate(-50%, calc(-100% - 1.5em)); }
    .mapmaker-toolbar-button.popover.open:first-of-type:not(:only-of-type) > .popover-content > * {
      left: 0;
      right: initial;
      transform: translate(0, calc(-100% - 1.5em)); }
    .mapmaker-toolbar-button.popover.open:last-of-type:not(:only-of-type) > .popover-content > * {
      right: 0;
      left: initial;
      transform: translate(0, calc(-100% - 1.5em)); } }
