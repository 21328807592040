input.aws-auth-input {
  outline: 0;
  line-height: 1.2em;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.3rem;
  padding: 0.75rem; }
  input.aws-auth-input[type="password"], input.aws-auth-input[type="text"], input.aws-auth-input[type="email"] {
    margin: 0;
    width: 100%;
    padding: 0.66em 1em; }
  input.aws-auth-input[type="checkbox"] {
    margin-right: 1em; }
  input.aws-auth-input:focus {
    border-color: #85b7d9;
    background: #fff;
    color: rgba(0, 0, 0, 0.8); }
