#context-panel-compact-section > .header {
  display: flex;
  width: 100%;
  background: var(--mm-black);
  color: #fff;
  padding: 1em;
  font-size: large; }
  #context-panel-compact-section > .header > .label {
    flex-grow: 1; }

#context-panel-compact-section > .content {
  padding: 1em;
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden; }
