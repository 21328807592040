#sanity-navigation {
  margin-left: 0;
  padding-left: 0;
  list-style: none; }
  @media (max-width: 768px) {
    #sanity-navigation {
      display: none; } }
  #sanity-navigation ul {
    list-style: none;
    margin-left: 0;
    padding-left: 1em;
    display: none; }
  #sanity-navigation ul.open {
    display: block; }
  #sanity-navigation li {
    padding: 6px 0.5em;
    font-size: 1.3rem;
    line-height: 1.4; }
  #sanity-navigation .link {
    display: flex;
    align-items: center;
    font-size: 1.2rem; }
    #sanity-navigation .link .label {
      margin: 0;
      color: red; }
    #sanity-navigation .link .label > a {
      color: var(--mm-black); }
    #sanity-navigation .link.selected {
      background: var(--mm-accent-color); }
      #sanity-navigation .link.selected .label > a {
        color: var(--mm-white); }
      #sanity-navigation .link.selected .icon {
        color: var(--mm-white); }
    #sanity-navigation .link .icon {
      color: var(--mm-grey); }
    #sanity-navigation .link:not(.hasChildren) .icon {
      visibility: hidden; }
    #sanity-navigation .link:not(.allowToggle) .icon {
      color: var(--mm-light-grey); }

#sanity-navigation-lite {
  font-size: 1.2rem;
  background: var(--mm-dark-white);
  border: 1px solid var(--mm-light-grey);
  margin: 1rem;
  padding: 1rem; }
  @media (min-width: 769px) {
    #sanity-navigation-lite {
      display: none; } }
  #sanity-navigation-lite .opener {
    cursor: pointer;
    display: flex;
    align-items: center; }
  #sanity-navigation-lite .icon {
    display: inline-block;
    font-weight: bold;
    width: 30px; }
  #sanity-navigation-lite ul {
    line-height: 1.5;
    list-style: none;
    padding-left: 30px;
    border-top: 1px solid var(--grey);
    padding-top: 1rem;
    margin-bottom: 0; }
  #sanity-navigation-lite li a {
    color: var(--mm-black);
    text-decoration: underline; }
