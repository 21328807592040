#output-type-settings > .content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 0.5rem; }

#output-type-settings .option {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center; }
  #output-type-settings .option:not(.selected) img {
    opacity: 0.7; }
