#opening-editor-view-toolbar {
  position: absolute;
  z-index: 5;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  flex-basis: 2rem;
  flex-direction: column; }
  #opening-editor-view-toolbar > * {
    width: 2.5rem;
    aspect-ratio: 1; }
  #opening-editor-view-toolbar .zoom-buttons {
    display: flex; }
  #opening-editor-view-toolbar .exit-icon {
    rotate: 45deg;
    font-size: 18pt;
    margin: 0 -6px; }
