.mapmaker-finder .header-message-container {
  background: var(--mm-dark-white);
  width: 100%; }

.mapmaker-finder .header-message,
.mapmaker-finder .mapmaker-file-grid {
  max-width: 1200px;
  margin: 0 auto; }

.mapmaker-finder .page-title {
  text-align: center;
  margin: 3rem 0; }
  @media (max-width: 767px) {
    .mapmaker-finder .page-title {
      margin: 1.5rem 0; } }

.mapmaker-finder .mapmaker-file-grid {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem; }
  @media (max-width: 1240px) {
    .mapmaker-finder .mapmaker-file-grid {
      margin: 2rem; } }
  @media (max-width: 767px) {
    .mapmaker-finder .mapmaker-file-grid {
      margin: 2rem 0; } }
  .mapmaker-finder .mapmaker-file-grid .mapmaker-new-project-tile {
    border: 1px dashed var(--mm-grey);
    aspect-ratio: 1;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--grey);
    cursor: pointer;
    background: #f8f8f8;
    /* Remove blue highlight on mobile tap */
    -webkit-tap-highlight-color: transparent; }
    @media (max-width: 767px) {
      .mapmaker-finder .mapmaker-file-grid .mapmaker-new-project-tile {
        margin: 15vw; }
        .mapmaker-finder .mapmaker-file-grid .mapmaker-new-project-tile .plus-icon {
          margin-top: -2.5rem; } }
    @media (min-width: 768px) {
      .mapmaker-finder .mapmaker-file-grid .mapmaker-new-project-tile {
        margin: 0; } }
    .mapmaker-finder .mapmaker-file-grid .mapmaker-new-project-tile .plus-icon {
      font-size: 10rem;
      text-align: center;
      line-height: 0.8; }
    .mapmaker-finder .mapmaker-file-grid .mapmaker-new-project-tile .label {
      text-transform: uppercase;
      font-size: 1.3rem; }
    .mapmaker-finder .mapmaker-file-grid .mapmaker-new-project-tile:hover {
      color: var(--mm-accent-color);
      border-color: var(--mm-accent-color); }
    .mapmaker-finder .mapmaker-file-grid .mapmaker-new-project-tile:active {
      border-color: var(--mm-white); }

.mapmaker-finder #guest-mode-warning {
  display: flex;
  align-items: center;
  gap: 3rem;
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto; }
  @media (max-width: 767px) {
    .mapmaker-finder #guest-mode-warning {
      gap: 1rem; } }
  .mapmaker-finder #guest-mode-warning .icon {
    color: var(--mm-warning-color); }
  .mapmaker-finder #guest-mode-warning a {
    filter: brightness(0.85); }
