#sticker-token-transaction-list {
  width: 100%;
  border-collapse: collapse; }
  #sticker-token-transaction-list th,
  #sticker-token-transaction-list td {
    padding: 0.25em 0.5em; }
  #sticker-token-transaction-list thead {
    text-align: left; }
    #sticker-token-transaction-list thead tr {
      background: var(--mm-light-grey); }
  #sticker-token-transaction-list tbody tr.transaction:hover:not(.open) {
    background: var(--mm-dark-white);
    cursor: pointer; }
  #sticker-token-transaction-list tbody tr.transaction.open,
  #sticker-token-transaction-list tbody tr.transaction-details {
    background: var(--mm-dark-white);
    cursor: pointer; }
  #sticker-token-transaction-list tbody td.date {
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  #sticker-token-transaction-list tbody td.note {
    max-width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  #sticker-token-transaction-list .details {
    padding: 0.5em;
    overflow: hidden; }
    #sticker-token-transaction-list .details > div {
      display: flex; }
      #sticker-token-transaction-list .details > div:not(:first-child) {
        margin-top: 2px; }
      #sticker-token-transaction-list .details > div > label {
        display: inline-block;
        flex-basis: 140px;
        font-weight: bold;
        font-size: 0.9em;
        flex-grow: 0;
        flex-shrink: 0; }
      #sticker-token-transaction-list .details > div > span {
        flex-shrink: 1;
        white-space: normal;
        word-break: break-all; }
  #sticker-token-transaction-list tr.no-transactions-message td {
    font-size: 1.2rem;
    color: var(--mm-grey);
    text-align: center;
    padding: 2em; }
