.full-map-view {
  position: relative; }

#full-map-view-menu {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1;
  display: flex;
  gap: 1rem; }
