@media screen and (max-width: 767px) {
  #mapmaker-project-not-found-page {
    padding: 0 1rem 2rem 1rem;
    width: 100%;
    align-self: flex-start; } }

@media screen and (min-width: 768px) {
  #mapmaker-project-not-found-page {
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    width: 100%;
    overflow: auto;
    align-items: center;
    justify-content: center;
    padding: 2rem 0; }
    #mapmaker-project-not-found-page .auth-box-container {
      overflow: hidden;
      background: var(--white);
      box-shadow: 0 1px 5px #00000055, 0px 15px 25px #00000011;
      padding: 2em;
      width: 540px;
      border-radius: 3px; }
      #mapmaker-project-not-found-page .auth-box-container .invite-message {
        margin-top: -2rem; } }

#mapmaker-project-not-found-page .invite-message {
  background: var(--mm-dark-white);
  margin-bottom: 2rem;
  margin-left: -2rem;
  margin-right: -2rem;
  padding: 1rem 3rem; }
  #mapmaker-project-not-found-page .invite-message .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    text-align: center; }
    #mapmaker-project-not-found-page .invite-message .header h1,
    #mapmaker-project-not-found-page .invite-message .header h3 {
      margin: 0; }
    #mapmaker-project-not-found-page .invite-message .header > svg {
      flex-shrink: 0;
      transform: rotate(-8deg);
      animation: wobble 0.8s linear 1.5s forwards; }
      @media screen and (max-width: 767px) {
        #mapmaker-project-not-found-page .invite-message .header > svg {
          font-size: 50pt; } }
  #mapmaker-project-not-found-page .invite-message p {
    margin-top: 1rem;
    color: var(--mm-light-black);
    text-align: center; }

@keyframes wobble {
  0% {
    transform: rotate(-8deg); }
  15% {
    transform: rotate(-10deg); }
  30% {
    transform: rotate(6deg); }
  45% {
    transform: rotate(-6deg); }
  60% {
    transform: rotate(4deg); }
  75% {
    transform: rotate(-2deg); }
  100% {
    transform: rotate(-8deg); } }

#mapmaker-project-not-found-page .alternatives {
  text-align: center;
  margin-top: 2rem;
  color: var(--mm-dark-grey); }
