#opening-image-preview {
  position: relative;
  overflow: hidden; }
  #opening-image-preview .uploading {
    position: absolute;
    bottom: 0;
    left: -10px;
    right: -10px;
    overflow-y: hidden;
    text-transform: uppercase;
    padding-bottom: 5px;
    padding-top: 7px;
    text-align: center;
    color: var(--mm-white);
    background: var(--mm-black);
    white-space: nowrap;
    font-size: 6pt; }
  #opening-image-preview .badge {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    border-left: 1px solid var(--mm-black);
    border-bottom: 1px solid var(--mm-black);
    border-radius: 0 0 0 3px;
    padding: 0px 0px 3px 4px;
    vertical-align: middle;
    text-align: center;
    font-size: 0.8rem; }
    #opening-image-preview .badge:empty {
      display: none; }
    #opening-image-preview .badge.warning {
      background: var(--mm-warning-color); }
    #opening-image-preview .badge.error {
      background: var(--mm-error-color); }

#opening-image-preview-add {
  user-select: none;
  border: 1px dashed;
  opacity: 0.8;
  background: var(--mm-grey);
  color: var(--mm-dark-white);
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
  position: relative; }
  #opening-image-preview-add > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  #opening-image-preview-add:hover {
    opacity: 1; }
  #opening-image-preview-add .icon {
    display: block;
    margin: 0 auto; }

#opening-image-preview-view-suggestions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  user-select: none;
  background: var(--mm-accent-color);
  color: var(--mm-white);
  border-radius: 3px;
  cursor: pointer; }
  #opening-image-preview-view-suggestions:hover {
    filter: brightness(1.1); }
  #opening-image-preview-view-suggestions .number {
    font-size: 2rem; }
