#sticker-tokens-modal {
  padding: 0 !important; }
  #sticker-tokens-modal > * {
    padding: 1rem; }
  #sticker-tokens-modal .free-token-warning {
    display: flex;
    align-items: center;
    background-color: var(--mm-discount-color);
    color: var(--mm-discount-text-color);
    gap: 1rem; }
    #sticker-tokens-modal .free-token-warning > :nth-child(1) {
      flex-shrink: 0;
      font-size: 20pt; }
  #sticker-tokens-modal .header {
    display: flex;
    align-items: center;
    background-color: var(--mm-dark-white); }
    @media screen and (max-width: 767px) {
      #sticker-tokens-modal .header {
        padding: 1rem;
        gap: 1rem; } }
    @media screen and (min-width: 768px) {
      #sticker-tokens-modal .header {
        padding: 2rem;
        gap: 2rem; } }
    #sticker-tokens-modal .header .token-icon {
      flex-shrink: 0; }
    #sticker-tokens-modal .header h2 {
      margin: 0;
      margin-bottom: 0.5rem; }
  #sticker-tokens-modal .main {
    display: flex; }
    #sticker-tokens-modal .main > * {
      margin-top: 1rem; }
    @media screen and (max-width: 767px) {
      #sticker-tokens-modal .main {
        flex-direction: column-reverse;
        gap: 2rem; }
        #sticker-tokens-modal .main .info {
          order: 2; }
        #sticker-tokens-modal .main .buy-box {
          order: 1; } }
    @media screen and (min-width: 768px) {
      #sticker-tokens-modal .main {
        flex-row: column;
        gap: 1rem; } }
    #sticker-tokens-modal .main .info > h2 {
      text-align: center; }
    #sticker-tokens-modal .main .info .steps {
      list-style: none;
      display: flex;
      gap: 1rem;
      margin: 0;
      padding: 0; }
      @media screen and (min-width: 768px) {
        #sticker-tokens-modal .main .info .steps {
          flex-direction: column; } }
      #sticker-tokens-modal .main .info .steps li {
        padding: 0;
        display: flex; }
        @media screen and (max-width: 767px) {
          #sticker-tokens-modal .main .info .steps li {
            flex-direction: column;
            gap: 1rem; }
            #sticker-tokens-modal .main .info .steps li img {
              height: 60px; } }
        @media screen and (min-width: 768px) {
          #sticker-tokens-modal .main .info .steps li {
            flex-direction: row;
            gap: 1rem;
            align-items: center; }
            #sticker-tokens-modal .main .info .steps li img {
              width: 70px;
              margin: 10px; } }
        #sticker-tokens-modal .main .info .steps li img {
          object-fit: contain;
          flex-shrink: 0; }
        #sticker-tokens-modal .main .info .steps li > div {
          max-width: 300px; }
          #sticker-tokens-modal .main .info .steps li > div h3 {
            margin: 0;
            margin-bottom: 0.5rem; }
          #sticker-tokens-modal .main .info .steps li > div p {
            text-align: left; }
    #sticker-tokens-modal .main .buy-box {
      text-align: center; }
      @media screen and (max-width: 767px) {
        #sticker-tokens-modal .main .buy-box {
          order: 2; } }
      @media screen and (min-width: 768px) {
        #sticker-tokens-modal .main .buy-box {
          display: flex;
          flex-direction: column;
          flex-basis: 300px;
          justify-self: center; } }
      #sticker-tokens-modal .main .buy-box .token-select {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        align-items: center;
        justify-content: center; }
        #sticker-tokens-modal .main .buy-box .token-select .token-option {
          position: relative;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          align-items: center;
          justify-content: center;
          gap: 0.25rem;
          flex-grow: 0;
          flex-basis: calc(50% - 0.5rem);
          min-width: 90px;
          max-width: 150px;
          aspect-ratio: 1;
          padding: 0.5rem;
          padding-top: 0.5rem;
          border: 1px solid var(--mm-light-grey);
          border-radius: 3px;
          cursor: pointer;
          user-select: none; }
          @media screen and (min-width: 768px) and (max-width: 1000px) {
            #sticker-tokens-modal .main .buy-box .token-select .token-option {
              min-height: 130px; } }
          #sticker-tokens-modal .main .buy-box .token-select .token-option:not(.selected) {
            background: var(--mm-dark-white);
            color: var(--mm-dark-grey); }
            #sticker-tokens-modal .main .buy-box .token-select .token-option:not(.selected) svg {
              opacity: 0.4; }
            #sticker-tokens-modal .main .buy-box .token-select .token-option:not(.selected) .label,
            #sticker-tokens-modal .main .buy-box .token-select .token-option:not(.selected) .price {
              color: var(--mm-grey); }
            #sticker-tokens-modal .main .buy-box .token-select .token-option:not(.selected) .discount {
              opacity: 0.5;
              filter: grayscale(1); }
          #sticker-tokens-modal .main .buy-box .token-select .token-option.selected {
            border-color: var(--mm-accent-color); }
          #sticker-tokens-modal .main .buy-box .token-select .token-option .label {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            font-size: 0.9rem;
            text-transform: uppercase;
            color: var(--mm-dark-grey);
            background: var(--mm-light-grey); }
          #sticker-tokens-modal .main .buy-box .token-select .token-option .tokens {
            font-size: 1.1rem;
            font-weight: bold; }
          #sticker-tokens-modal .main .buy-box .token-select .token-option .price {
            font-size: 0.9rem;
            font-weight: bold; }
          #sticker-tokens-modal .main .buy-box .token-select .token-option .discount {
            position: absolute;
            bottom: 1.5rem;
            right: 1.5rem;
            padding: 2px 0;
            width: 100px;
            text-align: center;
            color: var(--mm-discount-text-color);
            background: var(--mm-discount-color);
            transform: translate(50%, 50%) rotate(-45deg);
            font-family: "Avenir Next" !important;
            font-size: 7pt;
            font-weight: bold; }
          #sticker-tokens-modal .main .buy-box .token-select .token-option.custom-option .input-row {
            display: flex;
            gap: 0.25rem;
            align-items: center; }
          #sticker-tokens-modal .main .buy-box .token-select .token-option.custom-option input {
            font-size: 1.3rem;
            padding: 0.25rem;
            text-align: center;
            border: 1px solid var(--mm-grey);
            border-radius: 3px; }
            #sticker-tokens-modal .main .buy-box .token-select .token-option.custom-option input::selection {
              background: var(--mm-light-grey); }
          #sticker-tokens-modal .main .buy-box .token-select .token-option.custom-option .warning {
            color: var(--mm-warning-color); }
          #sticker-tokens-modal .main .buy-box .token-select .token-option.custom-option:not(.selected) input {
            background: transparent;
            color: var(--mm-grey); }
  #sticker-tokens-modal .learn-more a {
    color: var(--mm-dark-grey);
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center; }
