.add-file-access-modal .unfinished-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  gap: 1rem;
  font-size: 1.2rem; }
  .add-file-access-modal .unfinished-form textarea-label {
    margin-top: 1rem; }
  .add-file-access-modal .unfinished-form form,
  .add-file-access-modal .unfinished-form input,
  .add-file-access-modal .unfinished-form textarea {
    width: 100%; }
  .add-file-access-modal .unfinished-form input,
  .add-file-access-modal .unfinished-form textarea {
    padding: 1rem !important;
    width: 100%;
    border: 1px solid rgba(34, 36, 38, 0.15);
    transition: color 0.1s ease, border-color 0.1s ease; }
  .add-file-access-modal .unfinished-form .subtle {
    font-size: 0.8rem;
    font-weight: normal;
    color: var(--mm-dark-grey);
    display: block;
    text-transform: uppercase; }
  .add-file-access-modal .unfinished-form .add-note {
    text-transform: uppercase;
    color: var(--mm-grey);
    cursor: pointer;
    user-select: none; }
    .add-file-access-modal .unfinished-form .add-note:hover {
      color: var(--mm-light-black); }
    .add-file-access-modal .unfinished-form .add-note:active {
      margin-bottom: -1px; }

.add-file-access-modal .error {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  gap: 1rem;
  color: var(--mm-info-color); }
  .add-file-access-modal .error p {
    flex-grow: 1; }

.add-file-access-modal .success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 0.5rem;
  text-align: center;
  animation: fadeIn2 1s ease-out;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  opacity: 0; }

@keyframes fadeIn2 {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
