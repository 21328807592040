#sticker-token-redemption {
  display: flex;
  flex-direction: column;
  place-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto; }
  #sticker-token-redemption .above {
    text-align: center; }
  #sticker-token-redemption .redemption-form {
    width: 100%;
    margin: 4em 0; }
  #sticker-token-redemption .below {
    text-align: center;
    color: var(--grey); }
