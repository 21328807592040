#sanity-image {
  line-height: 0.5;
  text-align: center;
  margin: 2rem; }
  #sanity-image .image {
    max-width: 100%; }
  #sanity-image .caption {
    margin-top: 0.5rem;
    line-height: initial;
    font-weight: bold;
    font-size: 0.9rem;
    padding: 0 1rem; }
