#mapmaker-button {
  position: relative;
  display: inline-flex;
  place-items: center;
  justify-content: center;
  user-select: none;
  white-space: nowrap;
  border: unset;
  border-radius: 3px;
  font-weight: bold;
  /**
   * Colors
   **/
  /**
   * Sizes
   **/ }
  #mapmaker-button:not(.disabled):not(.loading) {
    cursor: pointer; }
    #mapmaker-button:not(.disabled):not(.loading):hover {
      filter: brightness(1.05); }
    #mapmaker-button:not(.disabled):not(.loading):active {
      filter: brightness(0.95); }
  #mapmaker-button.disabled {
    filter: grayscale(1) brightness(1.1); }
  #mapmaker-button.fluid {
    width: 100%; }
  #mapmaker-button.color-normal {
    background: var(--mm-light-grey);
    color: var(--mm-light-black); }
    #mapmaker-button.color-normal.disabled {
      color: var(--mm-grey); }
  #mapmaker-button.color-accent {
    background: var(--mm-accent-color);
    color: var(--mm-accent-text-color); }
  #mapmaker-button.color-delete {
    background: var(--mm-error-color);
    color: var(--mm-error-text-color); }
  #mapmaker-button.size-small {
    font-size: 0.9em;
    font-weight: normal;
    margin: 0 0.25em;
    padding: 0.25em 0.75em; }
  #mapmaker-button.size-medium {
    margin: 0 0.5em;
    padding: 0.75em 1.5em; }
  #mapmaker-button.size-large {
    margin: 0 0.5em;
    padding: 1em 2em; }
  #mapmaker-button > .loader {
    position: absolute;
    visibility: hidden;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
    #mapmaker-button > .loader::before {
      border-color: rgba(255, 255, 255, 0.5); }
    #mapmaker-button > .loader::after {
      border-top-color: var(--mm-white);
      border-bottom: transparent;
      border-left-color: transparent;
      border-right-color: transparent; }
  #mapmaker-button.loading {
    color: transparent; }
    #mapmaker-button.loading > :not(.loader) {
      visibility: hidden; }
    #mapmaker-button.loading > .loader {
      visibility: visible; }
