#sanity-navigation-child-links {
  margin-top: 3rem; }
  #sanity-navigation-child-links ul {
    padding-left: 1rem;
    font-size: 1.3rem;
    line-height: 1.5; }
    #sanity-navigation-child-links ul li {
      padding: 0.25rem 0 0.25rem 0.25rem; }

#sanity-navigation-sibling-links {
  display: flex;
  font-size: 1.3rem;
  margin-top: 4rem; }
  #sanity-navigation-sibling-links div:first-child {
    flex-grow: 1; }
  @media screen and (max-width: 768px) {
    #sanity-navigation-sibling-links {
      border-top: 1px solid var(--light-grey);
      margin-top: 2rem;
      padding-top: 2rem; } }
