.header {
  z-index: 500;

  --main-color: var(--dark-grey);
  --hover-color: var(--light-black);
  --active-color: var(--accent-color);

  &.inverse {
    --main-color: var(--white);
    --hover-color: var(--dark-white);
    --active-color: var(--white);
  }

  color: var(--main-color);
  a {
    color: var(--main-color);
    &:hover {
      color: var(--hover-color);
    }
  }

  .logo {
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    margin: 0 auto;
  }

  .spacer {
    flex-grow: 1;
  }
}

.mobileHeader {
  @media screen and (min-width: 768px) {
    display: none;
  }
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;

  .logo {
    height: 28px;
    top: 6px;
  }
}

.desktopHeader {
  display: none;
  @media screen and (max-width: 767px) {
    display: none;
  }

  position: relative;
  display: flex;
  align-items: center;
  height: var(--header-height);
  width: 100%;
  max-width: calc(var(--column-width) + 2rem);
  margin: 0 auto;
  padding: 0 1rem;

  .menuItem {
    position: relative;
    margin: 0;
    line-height: 0;
    font-size: 1.1rem;
    height: 26px;
    display: flex;
    align-items: center;

    .linkIcon {
      border-bottom: 2px solid transparent;
      padding-bottom: 3px;
      margin-bottom: -6px;
      font-size: 15pt;
      margin-top: -3px;
    }
  }

  .menuItem.active:not(.noHighlight) {
    a {
      text-decoration: underline;
      text-decoration-color: var(--active-color);
      text-decoration-thickness: 2px;
      text-underline-offset: 4px;
      .linkIcon {
        border-color: var(--active-color);
      }
    }
  }
  .menuItem:not(.active):hover:not(.noHighlight) {
    a {
      text-decoration: underline;
      text-decoration-color: currentColor;
      text-decoration-thickness: 2px;
      text-underline-offset: 4px;
      .linkIcon {
        border-color: currentColor;
      }
    }
  }

  .menuItem:after {
    content: "\00b7";
    margin-left: 15px;
    margin-right: 15px;
    vertical-align: middle;
  }

  .menuItem.noDivider:after {
    content: none;
  }

  .badge {
    position: absolute;
    right: -0.5rem;
    top: 50%;
    transform: translate(100%, -50%);
    color: var(--accent-text-color);
    margin-top: 1px;
    font-size: 10pt;
  }
}
