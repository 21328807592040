#mobile-menu {
  .email {
    text-transform: uppercase;
    font-size: 0.9em;
    font-weight: bold;
    color: #000;
  }
  .disabled .email {
    opacity: 0.3;
    pointer-events: none;
  }
}
