@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@900&display=swap");
#mapmaker-product-page {
  width: 100%; }
  @media (min-width: 1001px) {
    #mapmaker-product-page {
      background: var(--dark-white);
      border-top: 1px solid var(--light-grey);
      border-bottom: 1px solid var(--light-grey); } }
  #mapmaker-product-page .above-the-fold {
    width: 1600px;
    max-width: 100%;
    margin: 0 auto;
    display: flex; }
    @media (max-width: 1000px) {
      #mapmaker-product-page .above-the-fold {
        flex-direction: column; } }
    @media (min-width: 1001px) {
      #mapmaker-product-page .above-the-fold {
        flex-direction: row;
        padding: 2rem 1rem; } }
  @media (min-width: 1001px) and (max-width: 1200px) {
    #mapmaker-product-page .above-the-fold {
      padding: 1rem 0.5rem; } }
  #mapmaker-product-page .images {
    flex-grow: 1; }
  #mapmaker-product-page .options {
    flex-basis: Min(400px, 30%);
    flex-shrink: 0;
    padding: 0 1rem; }
    @media (max-width: 767px) {
      #mapmaker-product-page .options {
        padding: 0 1em; } }

#product-page-promotion {
  background: linear-gradient(#3e8b29, transparent), linear-gradient(to top left, #3e7134, transparent), linear-gradient(to top right, #236411, transparent);
  background-blend-mode: screen;
  display: flex;
  align-items: center;
  justify-content: center; }
  #product-page-promotion .promotion-container {
    max-width: var(--mm-column-width);
    padding: 1rem;
    color: var(--mm-white); }
    #product-page-promotion .promotion-container > :nth-child(1) {
      flex-shrink: 0; }
    #product-page-promotion .promotion-container > div {
      flex-grow: 1; }
    #product-page-promotion .promotion-container .title {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-family: "Roboto Slab", serif;
      margin: 0;
      margin-bottom: 0.5rem; }
    #product-page-promotion .promotion-container .description {
      margin: 1rem 0;
      font-size: 1rem;
      line-height: 1.2; }
    #product-page-promotion .promotion-container .fine-print {
      display: flex;
      font-size: 0.8rem;
      font-weight: bold; }
      #product-page-promotion .promotion-container .fine-print > :nth-child(1) {
        flex-grow: 1; }
      #product-page-promotion .promotion-container .fine-print a {
        color: var(--mm-white); }
