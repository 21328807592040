/*-------------------
      Site Colors 
--------------------*/
/*---  Main Colors  ---*/
/*---  Colors  ---*/
/*---  Light Colors  ---*/
/*---   Neutrals  ---*/
/*--- Colored Backgrounds ---*/
/*--- Colored Text ---*/
/*--- Colored Headers ---*/
/*--- Colored Border ---*/
/*-------------------
       Layout
--------------------*/
/*-------------------
      Mapmaker 
--------------------*/
/*-------------------
      Other 
--------------------*/
#walgreens-order-information {
  position: relative;
  min-height: 280px;
  margin-top: 1em;
  margin-bottom: 0em;
  font-size: 12pt; }
  @media screen and (max-width: 767px) {
    #walgreens-order-information {
      min-height: 480px; } }
  #walgreens-order-information .header > .icon {
    margin-left: 0.5em;
    font-size: 12pt;
    vertical-align: top;
    cursor: pointer; }
  #walgreens-order-information p {
    margin: 0; }
    #walgreens-order-information p.capitalize {
      text-transform: capitalize; }
  #walgreens-order-information .column.head.right,
  #walgreens-order-information .column.product.right,
  #walgreens-order-information .column.total.right {
    text-align: right; }
  #walgreens-order-information .column.head {
    padding-bottom: 0;
    margin-bottom: 4px; }
  #walgreens-order-information .column.product {
    padding-top: 0;
    padding-bottom: 0; }
    #walgreens-order-information .column.product .item-price {
      font-size: 10pt; }
  #walgreens-order-information .total-divider {
    width: 100%;
    border-top: 1px solid #999999; }
  #walgreens-order-information .column.total {
    margin-top: 0;
    padding-top: 0;
    font-weight: bold; }
    #walgreens-order-information .column.total .tax-note {
      font-weight: 300;
      color: #787878; }
