.opening-suggestion-review-view .hidden-banner {
  text-align: center;
  padding: 0.5rem;
  font-size: 1.5rem;
  color: var(--mm-grey);
  background: var(--mm-light-grey); }

.opening-suggestion-review-view .view-toggle {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 1;
  padding: 4px 10px 2px 10px;
  text-transform: uppercase;
  font-size: 10pt;
  border-radius: 3px;
  background: var(--mm-white);
  color: var(--mm-light-black);
  border: 1px solid var(--mm-light-black);
  cursor: pointer;
  user-select: none; }
  .opening-suggestion-review-view .view-toggle .icon {
    margin-top: 2px;
    margin-bottom: -2px;
    margin-right: 3px; }
  .opening-suggestion-review-view .view-toggle:active, .opening-suggestion-review-view .view-toggle:hover {
    background: var(--mm-light-black);
    color: var(--mm-white); }
