#editable-opening-overlay .first,
#editable-opening-overlay .second {
  fill-rule: evenodd;
  pointer-events: none;
  vector-effect: non-scaling-stroke; }

#editable-opening-overlay .first {
  fill: #ffffff;
  stroke: none;
  mix-blend-mode: saturation; }

#editable-opening-overlay .second {
  fill: RGBA(255, 255, 255, 0.85);
  stroke: var(--mm-black);
  stroke-width: 2; }
