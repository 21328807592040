#sanity-content {
  line-height: 30.6px;
  font-size: 1.3rem; }
  #sanity-content:not(:last-child) {
    margin-bottom: 2em; }
  #sanity-content h2 {
    margin-top: 3rem; }
  @media screen and (max-width: 768px) {
    #sanity-content h2 {
      margin-top: 2rem; } }
