.deprecated-context-panel-layout {
  display: flex; }
  @media screen and (min-width: 768px) {
    .deprecated-context-panel-layout {
      flex-direction: row; }
      .deprecated-context-panel-layout #context-panel {
        flex-basis: 360px;
        flex-shrink: 0;
        border-right: 1px solid var(--mm-grey);
        overflow-y: auto; } }
  @media screen and (max-width: 767px) {
    .deprecated-context-panel-layout {
      flex-direction: column; }
      .deprecated-context-panel-layout #context-panel {
        border-top: 1px solid var(--mm-grey);
        order: 2; } }
  .deprecated-context-panel-layout #content {
    flex-grow: 1;
    overflow-y: auto;
    padding-bottom: 2em; }
