#opening-view-layout {
  display: flex;
  width: 100%;
  height: 100%; }
  @media screen and (min-width: 768px) {
    #opening-view-layout {
      flex-direction: row; } }
  @media screen and (max-width: 767px) {
    #opening-view-layout {
      flex-direction: column-reverse; } }

#opening-view-layout-main {
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  flex-basis: 0; }
