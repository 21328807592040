#gift-note-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem; }
  #gift-note-modal .note {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1; }
    @media screen and (min-width: 768px) {
      #gift-note-modal .note {
        margin-top: 20px; } }
    #gift-note-modal .note .input {
      position: relative; }
    #gift-note-modal .note .bow {
      position: absolute;
      height: 60px;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      background: var(--mm-white); }
    #gift-note-modal .note textarea {
      padding: 1rem;
      padding-top: 45px;
      border: 1px solid var(--grey);
      width: 100%;
      font-size: 1.5rem;
      border-radius: 3px; }
      #gift-note-modal .note textarea:focus {
        border: 1px solid var(--mm-light-black); }
  #gift-note-modal .info {
    color: var(--mm-dark-grey); }
