#general-settings .input-row {
  position: relative;
  margin-top: 1.5rem; }
  #general-settings .input-row label {
    position: absolute;
    left: 1rem;
    top: 0;
    padding: 0 0.5rem;
    transform: translate(-0.5rem, -50%);
    background: var(--mm-white);
    display: inline-block;
    z-index: 1;
    font-size: 0.9rem;
    color: var(--mm-light-black); }
  #general-settings .input-row input {
    padding: 1rem;
    font-size: 1.1rem;
    border: 1px solid var(--mm-light-grey);
    border-radius: 4px;
    outline-color: var(--mm-accent-color); }
