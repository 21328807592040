#mapmaker-cart-page {
  display: flex; }
  @media (max-width: 767px) {
    #mapmaker-cart-page {
      flex-direction: column; }
      #mapmaker-cart-page #line-item:first-of-type {
        padding-top: 0;
        margin-top: 0; } }
  @media (min-width: 768px) {
    #mapmaker-cart-page {
      padding-top: 2em; } }
  #mapmaker-cart-page > .main {
    flex-grow: 1; }
    @media (max-width: 767px) {
      #mapmaker-cart-page > .main {
        flex-basis: 100%; } }
    @media (min-width: 768px) {
      #mapmaker-cart-page > .main {
        margin-right: 1em; } }
  #mapmaker-cart-page > .summary {
    flex-basis: 350px;
    flex-shrink: 0; }
    #mapmaker-cart-page > .summary > *:not(:first-child) {
      margin-top: 2em; }
    @media (max-width: 1000px) {
      #mapmaker-cart-page > .summary {
        flex-basis: 300px; } }
    @media (max-width: 767px) {
      #mapmaker-cart-page > .summary {
        flex-basis: 100%; } }
  #mapmaker-cart-page .empty-cart {
    text-align: center; }
