#sharing-link {
  display: flex;
  border: 1px solid var(--mm-light-grey);
  border-radius: 4px;
  white-space: nowrap;
  width: 100%; }
  #sharing-link div {
    padding: 4px 0.5em;
    background: var(--mm-light-grey);
    height: 100%;
    flex-grow: 0;
    margin: 0; }
  #sharing-link > input {
    padding: 4px 0.5em;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    border: none;
    margin-right: 1px;
    margin-left: -1px; }
  #sharing-link .copy-btn {
    user-select: none;
    cursor: pointer; }
    #sharing-link .copy-btn:hover {
      color: var(--mm-black); }
    #sharing-link .copy-btn:active {
      filter: brightness(1.1); }
    #sharing-link .copy-btn span {
      display: inline-block;
      vertical-align: top;
      margin-top: -1px; }
