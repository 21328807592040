#print-quantities-selector {
  margin: 10px 0; }
  #print-quantities-selector.disabled * {
    color: var(--mm-light-grey);
    border-color: var(--mm-light-grey);
    background: var(--mm-dark-white);
    cursor: auto; }
  #print-quantities-selector.disabled .ui.checkbox input:checked ~ label:after {
    color: var(--mm-light-grey); }
  #print-quantities-selector .unprintable label {
    color: var(--mm-error-color); }
  #print-quantities-selector .header {
    margin: 0;
    padding: 10px 1em;
    border-radius: 0; }
  #print-quantities-selector .list-toggles {
    float: right; }
    #print-quantities-selector .list-toggles a {
      text-decoration: underline;
      padding: 0 4px;
      cursor: pointer; }
      #print-quantities-selector .list-toggles a:last-child {
        padding-right: 0; }
      #print-quantities-selector .list-toggles a:hover {
        color: var(--mm-accent-color); }
      #print-quantities-selector .list-toggles a.disabled {
        color: var(--mm-light-grey);
        cursor: auto;
        text-decoration: none; }
  #print-quantities-selector .segment {
    margin: 0;
    width: 100%;
    padding: 0.5em 0;
    max-height: 10em;
    overflow-y: auto; }
  #print-quantities-selector .row {
    padding: 1px 1em; }
    #print-quantities-selector .row.unchecked label {
      color: var(--mm-light-grey); }
