/*-------------------
      Site Colors 
--------------------*/
/*---  Main Colors  ---*/
/*---  Colors  ---*/
/*---  Light Colors  ---*/
/*---   Neutrals  ---*/
/*--- Colored Backgrounds ---*/
/*--- Colored Text ---*/
/*--- Colored Headers ---*/
/*--- Colored Border ---*/
/*-------------------
       Layout
--------------------*/
/*-------------------
      Mapmaker 
--------------------*/
/*-------------------
      Other 
--------------------*/
#context-panel-section {
  border-bottom: 1pt solid #DCDDDE;
  /* Large Displays */
  /* Small Displays */ }
  #context-panel-section:last-child {
    border-bottom: none; }
  #context-panel-section .title-bar {
    display: flex;
    margin: 0;
    user-select: none; }
    #context-panel-section .title-bar .section-label {
      padding: 0;
      margin: 0; }
  #context-panel-section .exit-btn {
    display: none; }
  #context-panel-section .toggle-btn {
    display: none; }
  @media screen and (min-width: 768px) {
    #context-panel-section .title-bar {
      cursor: pointer;
      padding: 1em; }
    #context-panel-section .title-bar .toggle-btn {
      display: block;
      cursor: pointer;
      color: #DCDDDE;
      margin-top: 2px;
      font-weight: normal; }
    #context-panel-section.content-open .title-bar .toggle-btn {
      display: none; }
    #context-panel-section .title-bar:hover .toggle-btn {
      display: block;
      color: #545454; }
    #context-panel-section .section-label {
      flex-grow: 1;
      font-size: 14pt;
      font-weight: normal; }
    #context-panel-section .section-content {
      display: none;
      padding: 0 1em 1em 1em; }
    #context-panel-section.content-open .section-content {
      display: block; }
    #context-panel-section.primary .title-bar {
      display: none; }
    #context-panel-section.primary .section-content {
      text-align: center;
      padding: 1em; }
    #context-panel-section.hide-title-bar .title-bar {
      display: none; }
    #context-panel-section.hide-title-bar .section-content {
      padding-top: 1em; } }
  @media screen and (max-width: 767px) {
    #context-panel-section {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      max-height: 60vh;
      position: absolute;
      bottom: 0;
      z-index: 100;
      background: #fff;
      width: 100%; }
      #context-panel-section .title-bar {
        padding: 1em;
        background: #424B54;
        font-size: 14pt; }
        #context-panel-section .title-bar .section-label {
          flex-grow: 1;
          font-weight: normal;
          color: #FFFFFF; }
        #context-panel-section .title-bar .exit-btn {
          display: block;
          cursor: pointer;
          font-size: 16pt;
          color: #FFFFFF; }
      #context-panel-section .section-content {
        padding: 1em;
        overflow-y: auto; }
      #context-panel-section.closed {
        display: none; } }
