#sanity-navigation-breadcrumbs {
  list-style: none;
  margin: 0;
  padding: 1rem;
  font-size: 1.3rem;
  line-height: 1.5; }
  #sanity-navigation-breadcrumbs li,
  #sanity-navigation-breadcrumbs i {
    padding-right: 0.5rem; }
  #sanity-navigation-breadcrumbs li {
    display: inline-block;
    color: red;
    text-decoration: none; }
    #sanity-navigation-breadcrumbs li a {
      color: var(--mm-black);
      text-decoration: underline; }
    #sanity-navigation-breadcrumbs li:last-of-type a {
      text-decoration: none; }
  #sanity-navigation-breadcrumbs i {
    color: var(--mm-grey); }
