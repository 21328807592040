#output-type-message {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 1rem 2rem;
  background: var(--dark-white);
  border-bottom: 1px solid var(--mm-grey); }
  @media screen and (max-width: 767px) {
    #output-type-message {
      gap: 1rem;
      padding: 1rem; } }
