html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen and (min-width: 768px) {
  .small-screen-only {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .large-screen-only {
    display: none;
  }
}

a {
  color: var(--link-color);
}
a:hover {
  color: var(--link-color-hover);
}

:root {
  --header-height: 85px;
  --column-width: 1200px;

  --walgreens-red: #e51937;

  /* Situational colors */
  --primary-color: #ffffff;
  --accent-color: #87cd21;
  --accent-color-light: #f8ffee;
  --accent-text-color: var(--white);
  --text-color: #181818;
  --link-color: #4183c4;
  --link-color-hover: var(--info-color);
  --info-color: #226e85;
  --info-color-light: #e7f9ff;
  --info-text-color: var(--white);
  --warning-color: #cd8721;
  --warning-text-color: var(--light-black);
  --error-color: #db2828;
  --error-color-background: #ffe8e6;
  --error-text-color: var(--white);
  --discount-color: #087d16;
  --discount-text-color: var(--white);

  /* Specific colors */
  --black: #3f3f37;
  --light-black: #545454;

  --light-grey: #dcddde;
  --grey: #999999;
  --dark-grey: #6e6e6e;

  --white: #ffffff;
  --dark-white: #f3f4f5;

  /* AWS Auth Variables */
  --auth-box-action-color: var(--accent-color);
  --auth-box-action-text-color: var(--accent-text-color);
  --auth-box-link-color: var(--link-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  font-family: Montserrat, Lato, "Brandon Grotesk", Roboto;
}

/* 
 * IOS automatically zooms input fields when the font size is less than 16px. This should help
 * minimize those scenarios. for more information see:
 * https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone
 */
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}
