:root {
  --auth-box-primary-color: #dddddd;
  --auth-box-primary-text-color: #333333;
  --auth-box-secondary-color: #00b5ad;
  --auth-box-secondary-text-color: #ffffff;
  --auth-box-error-color: #b83a3a;
  --auth-box-action-color: var(--auth-box-secondary-color);
  --auth-box-action-text-color: var(--auth-box-secondary-text-color);
  --auth-box-link-color: var(--auth-box-action-color); }

#auth-box #auth-box-header {
  margin-bottom: 1.5em; }
  #auth-box #auth-box-header .header-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 0.5em; }
    #auth-box #auth-box-header .header-row h3 {
      flex-grow: 1; }
    #auth-box #auth-box-header .header-row .alternative {
      flex-grow: 0;
      margin-top: 2px;
      font-size: 0.9em; }

#auth-box a,
#auth-box .link {
  cursor: pointer;
  color: var(--auth-box-link-color); }
  #auth-box a:hover,
  #auth-box .link:hover {
    filter: brightness(1.1); }

#auth-box #auth-box-form {
  /**
    * Removing the checkbox and radio appearance
    */
  /**
    * Giving Checkbox & Radio not checked a new appearance
    */
  /**
    * Giving Checkbox & Radio checked state a new appearance
    */ }
  #auth-box #auth-box-form .field {
    font-size: 0.9em; }
    #auth-box #auth-box-form .field.error input {
      border-color: var(--auth-box-error-color); }
  #auth-box #auth-box-form label {
    display: inline-block;
    margin-bottom: 0.5em; }
  #auth-box #auth-box-form .buttons {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    text-align: center; }
  #auth-box #auth-box-form input {
    outline: 0;
    line-height: 1.2em;
    font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
    background: #fff;
    border: 1px solid rgba(34, 36, 38, 0.15);
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0.3rem; }
  #auth-box #auth-box-form input[type="password"],
  #auth-box #auth-box-form input[type="text"],
  #auth-box #auth-box-form input[type="email"] {
    margin: 0;
    width: 100%;
    padding: 0.66em 1em; }
  #auth-box #auth-box-form input[type="checkbox"] {
    margin-right: 1em; }
  #auth-box #auth-box-form input:focus {
    border-color: #85b7d9;
    background: #fff;
    color: rgba(0, 0, 0, 0.8); }
  #auth-box #auth-box-form [type="radio"],
  #auth-box #auth-box-form [type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
  #auth-box #auth-box-form [type="radio"],
  #auth-box #auth-box-form [type="checkbox"] {
    width: 20px;
    height: 20px;
    border: solid 1px #cccccc;
    margin-right: 8px;
    margin-bottom: -5px;
    position: relative; }
  #auth-box #auth-box-form [type="radio"]:checked::before,
  #auth-box #auth-box-form [type="checkbox"]:checked::before {
    content: "";
    width: 14px;
    height: 14px;
    background-color: var(--auth-box-action-color);
    position: absolute;
    top: 2px;
    left: 2px; }
