/*-------------------
      Site Colors 
--------------------*/
/*---  Main Colors  ---*/
/*---  Colors  ---*/
/*---  Light Colors  ---*/
/*---   Neutrals  ---*/
/*--- Colored Backgrounds ---*/
/*--- Colored Text ---*/
/*--- Colored Headers ---*/
/*--- Colored Border ---*/
/*-------------------
       Layout
--------------------*/
/*-------------------
      Mapmaker 
--------------------*/
/*-------------------
      Other 
--------------------*/
#context-panel-select {
  margin: 10px 0; }
  #context-panel-select > .dropdown {
    border: 1px solid #DCDDDE;
    border-radius: 0; }
  #context-panel-select > .dropdown:hover {
    border: 1px solid #999999; }
  #context-panel-select > .dropdown.disabled {
    opacity: 1;
    background: #F3F4F5;
    border: 1px solid #DCDDDE;
    color: #DCDDDE;
    cursor: auto; }
