.owner-sharing-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden; }
  .owner-sharing-view .header {
    flex-grow: 0;
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    z-index: 5; }
    .owner-sharing-view .header .title {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      align-items: center; }
      .owner-sharing-view .header .title h3 {
        display: inline-block;
        margin: 0; }
      .owner-sharing-view .header .title .count {
        color: var(--mm-dark-grey);
        margin-bottom: -4px;
        font-size: 0.9rem; }
      .owner-sharing-view .header .title .label {
        max-width: 150px; }
      @media screen and (max-width: 767px) {
        .owner-sharing-view .header .title {
          gap: 0.25rem; }
          .owner-sharing-view .header .title .label {
            font-size: 0.8rem; } }
  .owner-sharing-view .no-suggestions-message {
    max-width: 550px;
    padding: 0 2rem; }
    .owner-sharing-view .no-suggestions-message .getting-started-buttons {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 0.5rem;
      align-items: center;
      justify-content: center; }
      .owner-sharing-view .no-suggestions-message .getting-started-buttons > * {
        min-width: 200px; }
    @media screen and (max-width: 767px) {
      .owner-sharing-view .no-suggestions-message {
        width: 100vw;
        padding: 2rem; }
        .owner-sharing-view .no-suggestions-message > svg {
          font-size: 80pt; }
        .owner-sharing-view .no-suggestions-message h2 {
          font-size: 1.3rem;
          word-wrap: break-word;
          hyphens: auto; }
        .owner-sharing-view .no-suggestions-message p {
          font-size: 0.9rem; } }
  .owner-sharing-view .hidden-suggestions-message {
    text-align: center;
    padding: 4rem; }
    @media screen and (max-width: 767px) {
      .owner-sharing-view .hidden-suggestions-message {
        padding: 1rem;
        padding-bottom: 2rem; } }
    .owner-sharing-view .hidden-suggestions-message .link,
    .owner-sharing-view .hidden-suggestions-message .link {
      display: inline-block;
      margin: 1rem;
      font-size: 1rem; }
    .owner-sharing-view .hidden-suggestions-message p {
      color: var(--mm-grey);
      font-size: 0.9rem; }

.owner-sharing-context-panel {
  display: flex;
  flex-direction: column; }
  .owner-sharing-context-panel .file-access-header {
    padding: 1rem;
    display: flex;
    gap: 1rem;
    align-items: center; }
    .owner-sharing-context-panel .file-access-header .expander {
      color: var(--mm-grey); }
      @media screen and (min-width: 768px) {
        .owner-sharing-context-panel .file-access-header .expander {
          display: none; } }
    .owner-sharing-context-panel .file-access-header h3 {
      flex-grow: 1;
      margin: 0; }
    .owner-sharing-context-panel .file-access-header button {
      flex-grow: 0; }
